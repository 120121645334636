/* eslint-disable class-methods-use-this */
import store from '../../shared/Store';
import platformService from './platform.service';

type CheckWorkshopFeedbackResult =
  | {
      status: 'none';
    }
  | { status: 'display'; uri: string }
  | { status: 'done' };

class FeedbackService {
  async checkWorkshopFeedback(
    feedbackFormUri: string,
    workshopId: string,
  ): Promise<CheckWorkshopFeedbackResult> {
    if (!feedbackFormUri) return { status: 'none' };
    const api = `${feedbackFormUri.replace('/html', '/api')}/workshops/${workshopId}/users/${
      store.userId
    }`;
    const res = await platformService.secureGet(api);
    if (res.status === 'display') {
      return { ...res, uri: `${feedbackFormUri}/workshops/${workshopId}?token=${res.token}` };
    }
    return res;
  }
}

export default new FeedbackService();

import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router-dom';
import { Header, Message } from 'semantic-ui-react';

import { useDesignConfig } from '../config/design.context';
import store, { getParameterByName } from '../shared/Store';
import { markdownUrlify } from '../utils/stringUtils';
import LoginLayout from './LoginLayout';
import LoginPasswordForm from './components/LoginPasswordForm';
import OneTimePasswordForm from './components/OneTimePasswordForm';
import { useForceUpdate, useLoginConfig } from './hooks';

const translationPrefix = 'login';

const loginModes = {
  otp: OneTimePasswordForm,
  password: LoginPasswordForm,
};

const LoginDisclaimerHeader = ({ logoutReason }) => {
  const { t } = useTranslation();

  let msg = '';
  let color;

  switch (logoutReason) {
    case 'auth_expired': {
      color = 'blue';
      msg = t(`${translationPrefix}.disclaimers.expired`);
      break;
    }

    default:
      break;
  }

  if (!msg) return null;

  return (
    <Message color={color}>
      <p>{msg}</p>
    </Message>
  );
};

LoginDisclaimerHeader.defaultProps = {
  logoutReason: '',
};

LoginDisclaimerHeader.propTypes = {
  logoutReason: PropTypes.string,
};

const LoginScreen = () => {
  const { t } = useTranslation();
  const design = useDesignConfig();
  const login = useLoginConfig();
  const forceUpdate = useForceUpdate();
  const { state } = useLocation();
  const { tokenExpired = false } = state || {};
  const { mode = 'password', ctaSection, layout = {}, redirectTo } = login;

  if (store.isLoggedIn()) {
    return <Redirect to={getParameterByName('success_redirect') || redirectTo || '/'} />;
  }

  const logoutReason = getParameterByName('logout_reason') || '';
  const { primaryColor: color } = design;
  const { variant } = layout;

  const notYetRegistered = t(`${translationPrefix}.not-yet-registered`, '');
  const LoginComponent = loginModes[mode] || loginModes.password;
  return (
    <LoginLayout screen="login" variant={variant} sideSection={ctaSection}>
      <div className="platform-opened">
        <LoginDisclaimerHeader logoutReason={logoutReason} />
        <Header as="h2" style={{ color }} textAlign="center">
          {t(`${translationPrefix}.connect-to-your-account`, '')}
        </Header>
        <LoginComponent onLogin={forceUpdate} />
        {notYetRegistered && (
          <p className="text--not-yet-registered">{markdownUrlify(notYetRegistered)}</p>
        )}
        {tokenExpired && (
          <Message
            className="error-message"
            error
            visible
            header={t(`${translationPrefix}.error`)}
            content={t(`${translationPrefix}.link-has-expired`)}
          />
        )}
      </div>
    </LoginLayout>
  );
};

export default LoginScreen;

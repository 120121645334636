import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Button, Header, Icon, Label, Modal } from 'semantic-ui-react';

import { getEventInfo } from './utils';

import ClickmeetingAccessButton from '../ClickmeetingAccessButton';
import ExpiringNavLink from '../ExpiringNavLink';

import { TrackingContext } from '../../Context';
import { bem } from '../../core/design/bem';
import { cancelAppointment } from '../../sponsors/blocks/BookAppointmentBlock/utils';
import { unregisterWorkshopSession } from '../../workshop-session/store/workshopSessions.hooks';

const translationPrefix = 'components.day-calendar.popup';

const cxCalendar = bem('day-calendar');
function DayCalendarPopup(props) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { trackEvent } = useContext(TrackingContext);
  const { event, config, onClose } = props;
  const { types = [] } = config;
  const { collection, title } = event;

  const { color, label, to } = getEventInfo(types, event);

  const handleUpdate = () => {
    if (pathname === to) {
      onClose();
    }
  };

  // eslint-disable-next-line consistent-return
  const handleCancel = async () => {
    const { type: eventType } = event;
    try {
      switch (eventType) {
        case 'session': {
          return unregisterWorkshopSession(event, { trackEvent });
        }
        case 'appointment': {
          return cancelAppointment(event, { name: title, collection }, { trackEvent });
        }
        default:
          return null;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      onClose();
    }
  };
  return (
    <Modal size="small" open onClose={onClose} closeIcon className={cxCalendar('popup').toString()}>
      <Modal.Content>
        <div className="header">
          <Header as="h2" className="title">
            {title}
          </Header>
          <div className="action-container">
            <ClickmeetingAccessButton event={event} />
          </div>
        </div>
        <Label className="type" style={{ backgroundColor: color, color: 'white' }}>
          {label}
        </Label>
        <div className="datetime"> {t(`${translationPrefix}.time`, event)}</div>
        <hr />
        <div className="prepare">
          <Header as="h4">{t(`${translationPrefix}.prepare-your-appointment`)}</Header>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Icon name="microphone" />
            <div>
              <p>{t(`${translationPrefix}.connexion-test-label`)}</p>
              <Button
                as="a"
                target="_blank"
                href="https://utilities.clickmeeting.com/connection-tester"
              >
                {t(`${translationPrefix}.connexion-test-btn`)}
              </Button>
            </div>
          </div>
        </div>
        <div className="cancel">
          <Header as="h4">{t(`${translationPrefix}.impediment`)}</Header>
          <Button
            className="update"
            as={to ? ExpiringNavLink : undefined}
            to={to}
            onClick={handleUpdate}
          >
            {t('btn.edit')}
          </Button>
          <Button className="cancel" onClick={handleCancel}>
            {t('btn.cancel')}
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
}

DayCalendarPopup.defaultProps = {
  config: {},
};

DayCalendarPopup.propTypes = {
  config: PropTypes.object,
  event: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DayCalendarPopup;

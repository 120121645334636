export const START_CHAT = 'NETWORKING_CHAT_START_CHAT';
export const STOP_CHAT = 'NETWORKING_CHAT_STOP_CHAT';
export const LOAD_MORE_MESSAGES = 'NETWORKING_CHAT_LOAD_MORE_MESSAGES';
export const CHAT_SEND_MESSAGE = 'NETWORKING_CHAT_SEND_MESSAGE';
export const CHAT_SEND_VISIO_REQUEST = 'NETWORKING_CHAT_SEND_VISIO_REQUEST';
export const CHAT_SEND_VISIO_ENDED = 'NETWORKING_CHAT_SEND_VISIO_ENDED';
export const CHAT_DELETE_MESSAGE = 'NETWORKING_CHAT_DELETE_MESSAGE';
export const ACCEPT_INVITE = 'NETWORKING_ACCEPT_INVITE';
export const REFUSE_INVITE = 'NETWORKING_REFUSE_INVITE';
export const SEND_INVITE = 'NETWORKING_SEND_INVITE';

export const CHAT_STARTED = 'NETWORKING_CHAT_STARTED';
export const CHAT_STOPPED = 'NETWORKING_CHAT_STOPPED';
export const MESSAGES_LOADED = 'NETWORKING_CHAT_MESSAGES_LOADED';
export const MESSAGE_DELETED = 'NETWORKING_CHAT_MESSAGE_DELETED';
export const INVITE_RECEIVED = 'NETWORKING_INVITE_RECEIVED';
export const CHAT_LOADED = 'NETWORKING_CHAT_LOADED';
export const CHAT_REMOVED = 'NETWORKING_CHAT_REMOVED';
export const CHAT_LIST_LOADED = 'NETWORKING_CHAT_LIST_LOADED';
export const INVITE_SENT = 'NETWORKING_INVITE_SENT';

export const NetworkingCollectionKey = 'networking';
export const NetworkingChatsCollectionKey = 'chats';
export const NetworkingMessagesCollectionKey = 'messages';

import React, { FC } from 'react';
import { Modal } from 'semantic-ui-react';

import { useSelectedAchievements } from '../../../core/achievements/achievements.hook';
import { bem } from '../../../core/design/bem';
import { useFatMe } from '../../../profile/hooks';
import IframeBlock from '../../cms/IframeBlock';

const css = bem('BlockingPopup');

type BlockedByAchievementPopupProps = {
  uri: string;
  iframeProps?: Record<string, string | number>;
  achievementIds: string[];
} & typeof BlockedByAchievementPopupDefaultProps;

const BlockedByAchievementPopupDefaultProps = {
  iframeProps: {},
};

const BlockedByAchievementPopup: FC<BlockedByAchievementPopupProps> = (props) => {
  const { achievementIds, iframeProps, uri } = props;

  const blockAchievement = useSelectedAchievements(achievementIds);
  const { userAchievementsByAchievementId } = useFatMe();

  if (achievementIds?.length === 0) return null;

  const unlockedAchievements = blockAchievement?.map(
    ({ _id }: { _id: string }) => !!userAchievementsByAchievementId[_id],
  );
  const isAchievementsDone = unlockedAchievements?.every((a: boolean) => a);

  if (!unlockedAchievements || isAchievementsDone) return null;
  return (
    <Modal open basic className={css.toString()}>
      <Modal.Content>
        <IframeBlock src={uri} {...iframeProps} />
      </Modal.Content>
    </Modal>
  );
};

BlockedByAchievementPopup.defaultProps = BlockedByAchievementPopupDefaultProps;

export default BlockedByAchievementPopup;

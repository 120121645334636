import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useUserAchievements } from '../core/achievements/achievements.hook';

export function useMe() {
  //TODO : if achievement enabled
  const user = useSelector((state) => state.user?.user);
  return user;
}

export function useFatMe() {
  //TODO : if achievement enabled
  const user = useSelector((state) => state.user?.user);
  const userAchievements = useUserAchievements();
  // WARNING : userAchievements is always re-computed...
  return useMemo(() => ({ ...user, ...userAchievements }), [user, userAchievements]);
}

export function useIsAnonymous() {
  const user = useMe();
  return !user || user.userType === 'anonymous';
}

import cx from 'classnames';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import React from 'react';
import { Header, Segment } from 'semantic-ui-react';

import { useTracking } from '../../../Context';
import { useSelectedAchievements } from '../../../core/achievements/achievements.hook';
import { bem } from '../../../core/design/bem';
import { eventTags } from '../../../core/trackers/events';
import { achievementsPropTypes, imageProptypes } from '../../../propTypes';
import Images from '../../../utils/Images';
import CdnImage from '../../CdnImage';
import ExpiringNavLink from '../../ExpiringNavLink';
import ImageIcon from '../../ImageIcon';
import SquareContainer from '../../SquareContainer';
import './CTATileBlock.scss';
import { ensureTarget } from './utils';
import CTACard from './variants/CTACard.tsx';

const css = bem('CTATile');

function CTATileBlock(props) {
  const {
    achievements,
    _id,
    title,
    subtitle,
    icon,
    to,
    backgroundImage,
    style,
    variant,
    animation,
    target,
    imageProps,
    maxImageWidth,
    contentPosition,
    aspectRatio,
    onClick,
    ...rest
  } = props;

  const { ids: achievementsIds } = achievements || {};
  const blockAchievements = useSelectedAchievements(achievementsIds);

  const { showMoreButton, iconConfig = {} } = rest;
  const { trackEvent } = useTracking();
  const handleClick = () => {
    trackEvent(eventTags.CTA_GO_TO, {
      url: to,
      ...rest,
      item: pick(props, ['_id', 'title', 'subtitle', 'to']),
    });
    if (onClick) onClick(props);
  };

  if (variant === 'card') {
    return (
      <CTACard
        achievements={blockAchievements}
        className={css({ variant, _id }).toString()}
        to={to}
        onClick={handleClick}
        target={target}
        image={backgroundImage}
        title={title}
        subtitle={subtitle}
        style={style}
        imageProps={imageProps}
        aspectRatio={aspectRatio}
        showMoreButton={showMoreButton}
      />
    );
  }

  if (variant === 'image') {
    return (
      <Segment
        as={to ? ExpiringNavLink : undefined}
        to={to}
        target={ensureTarget(target, to)}
        className={css({ variant, animation, _id }).toString()}
        style={style}
        onClick={handleClick}
      >
        <CdnImage src={backgroundImage} maxWidth={maxImageWidth} srcSet />
        <div className={css('content')} style={{ position: 'absolute' }}>
          <Header as="p" icon>
            {icon && <ImageIcon icon={icon} {...iconConfig} />}
            {title}
            {subtitle && <Header.Subheader>{subtitle}</Header.Subheader>}
          </Header>
        </div>
      </Segment>
    );
  }

  if (variant === 'square') {
    const content = (
      <Header as="p" icon>
        {icon && <ImageIcon icon={icon} {...iconConfig} />}
        {title}
        {subtitle && <Header.Subheader>{subtitle}</Header.Subheader>}
      </Header>
    );
    return (
      <>
        <Segment
          as={to ? ExpiringNavLink : undefined}
          to={to}
          target={ensureTarget(target, to)}
          inverted={!!backgroundImage}
          className={cx(
            css({ variant, animation, _id }).toString(),
            !!backgroundImage && 'segment--background-image',
          )}
          style={style}
          onClick={handleClick}
        >
          <SquareContainer backgroundImage={backgroundImage}>
            {(!contentPosition || contentPosition === 'inside') && content}
          </SquareContainer>
        </Segment>
        {contentPosition === 'below' && (
          <div className={css('content', { below: true })}>{content}</div>
        )}
      </>
    );
  }

  // prettier-ignore
  const image = style?.height ?
    Images.maxHeight(backgroundImage) :
    Images.maxWidth(backgroundImage, 600);

  return (
    <Segment
      as={to ? ExpiringNavLink : undefined}
      to={to}
      target={ensureTarget(target, to)}
      inverted={!!backgroundImage}
      className={cx(css().toString(), !!backgroundImage && 'segment--background-image')}
      style={{
        ...style,
        backgroundImage: image ? `url("${image}"` : undefined,
      }}
      onClick={handleClick}
    >
      <Header as="p" icon={icon || undefined} content={title} subheader={subtitle || undefined} />
    </Segment>
  );
}

CTATileBlock.defaultProps = {
  achievements: undefined,
  aspectRatio: undefined,
  animation: undefined,
  backgroundImage: undefined,
  contentPosition: undefined,
  imageProps: {},
  icon: undefined,
  onClick: undefined,
  target: undefined,
  style: undefined,
  subtitle: undefined,
  title: undefined,
  maxImageWidth: 1200,
  variant: 'segment',
};

CTATileBlock.propTypes = {
  _id: PropTypes.string.isRequired,
  achievements: achievementsPropTypes,
  animation: PropTypes.oneOf(['scale']),
  aspectRatio: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  backgroundImage: imageProptypes,
  contentPosition: PropTypes.string,
  icon: PropTypes.string,
  imageProps: PropTypes.object,
  maxImageWidth: PropTypes.number,
  onClick: PropTypes.func,
  style: PropTypes.object,
  subtitle: PropTypes.string,
  target: PropTypes.string,
  title: PropTypes.string,
  to: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['segment', 'square', 'card', 'image']),
};

export default CTATileBlock;

import get from 'lodash/get';
import partition from 'lodash/partition';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { Dropdown, Icon, Menu, Sidebar } from 'semantic-ui-react';
import { bem } from '../../core/design/bem';
import BurgerUser from '../../Layouts/VideoBackground/components/BurgerMenu/BurgerUser';
import { userProptypes } from '../../propTypes';
import store from '../../shared/Store';
import AppHeader, { handleChangeLanguage } from '../AppHeader';
import CdnImage from '../CdnImage';
import ExpiringNavLink from '../ExpiringNavLink';
import ImageIcon from '../ImageIcon';
import EntitySearchBar from '../SearchBar/EntitySearchBar';
import { SecondaryMenuEntry } from './AppSecondaryMenu';
import ScreenOverlay from './components/ScreenOverlay';
import SidebarUser from './components/SidebarUser/SidebarUser';
import './menu.scss';

const css = bem('mobile-menu');
const sidebarMenu = bem('sidebar--menu');

const MobileMenuEntry = ({ entry, onChange }) => {
  const { name, label, path, icon, pageId, type, children } = entry;
  return (
    <Menu.Item
      className={css('item', { pageId: name || pageId, type }).toString()}
      as={path ? ExpiringNavLink : undefined}
      to={path}
      exact
      activeClassName="active"
      onClick={onChange}
    >
      <ImageIcon
        icon={icon}
        style={{
          height: 28,
        }}
        maxWidth={56}
      />
      {label}
      <Menu.Menu>
        {children?.map((child) => (
          <Menu.Item
            key={child.name}
            className={`${css('item', { pageId: name || pageId, type }).toString()} subitem`}
            as={child.path ? ExpiringNavLink : undefined}
            to={child.path}
            exact
            activeClassName="active"
            onClick={onChange}
          >
            {child.label}
          </Menu.Item>
        ))}
      </Menu.Menu>
    </Menu.Item>
  );
};

MobileMenuEntry.propTypes = {
  entry: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
class MenuMobile extends PureComponent {
  state = {
    isVisible: false,
  };

  handleDisconnect = async () => {
    await store.disconnect();
    this.forceUpdate();
  };

  toggleMenuBars = () => {
    this.setState((prevState) => ({ isVisible: !prevState.isVisible }));
  };

  handleChange = () => {
    this.setState({ isVisible: false });
  };

  render() {
    const { menu, language, user, languageOptions, design, loginPath } = this.props;
    const isAnonymous = user?.collection === 'anonymous';
    const { logo, logoProps, menu: menuConfig } = design;
    const { fixed, user: userMenu } = menuConfig?.mobile || {};
    const showLogo = get(menuConfig, ['mobile', 'showLogo'], false);

    if (store.mustRedirectToLogin()) {
      // Handle disconnect
      return <Redirect to={loginPath} />;
    }
    const [main, secondary] = partition(menu, ({ menuType }) => menuType === 'main');
    const { isVisible } = this.state;
    return (
      <div className={sidebarMenu({ fixed }).toString()}>
        <div className={sidebarMenu('header').toString()}>
          <Icon name="bars" onClick={this.toggleMenuBars} />
          <AppHeader
            secondary={(secondary || []).filter((i) => i.mobile)}
            language={language}
            languageOptions={languageOptions}
            onChange={this.handleChange}
          />
          {isAnonymous ? (
            <div style={{ width: 36, height: 20 }} />
          ) : (
            <Icon name="power off" onClick={this.handleDisconnect} />
          )}
        </div>
        <EntitySearchBar className="sidebar--menu__search--bar" />
        <ScreenOverlay
          visible={isVisible}
          onClick={(e) => {
            e.stopPropagation();
            this.setState({ isVisible: false });
          }}
        />
        <Sidebar
          className="sidebar--menu__container"
          animation="overlay"
          onHide={() => {
            this.setState({ isVisible: false });
          }}
          visible={isVisible}
          width="thin"
        >
          {showLogo && (
            <div className="sidebar--menu__container--header">
              <Icon
                name="times"
                style={{ position: 'absolute', left: 10, right: 10 }}
                onClick={() => this.setState({ isVisible: false })}
              />
              <CdnImage
                as="img"
                className="logo--main"
                src={logo}
                maxHeight={60}
                alt="event logo main"
                {...logoProps}
              />
            </div>
          )}
          <Menu className={css('main').toString()} vertical icon="labeled">
            {userMenu?.show && <SidebarUser {...userMenu} />}
            <BurgerUser config={menuConfig?.mobile} onDisconnect={this.handleDisconnect} />
            {main.map((entry) => (
              <MobileMenuEntry
                key={entry.name}
                entry={entry}
                onChange={() => {
                  this.handleChange();
                  if (entry.type === 'logout') {
                    this.handleDisconnect();
                  }
                }}
              />
            ))}
          </Menu>
          {secondary?.length > 0 && (
            <Menu
              className={css('secondary').toString()}
              vertical
              icon="labeled"
              style={{ width: '100%', margin: 0 }}
            >
              {secondary.map((entry) => (
                <SecondaryMenuEntry key={entry.name} entry={entry} onChange={this.handleChange} />
              ))}
            </Menu>
          )}
          {languageOptions && languageOptions.length > 1 && (
            <div className="dropdown--container language">
              <Dropdown
                item
                className="dropdown--languages"
                placeholder="Language"
                compact
                selection
                options={languageOptions}
                value={language}
                onChange={handleChangeLanguage}
              />
            </div>
          )}
        </Sidebar>
      </div>
    );
  }
}

MenuMobile.defaultProps = {
  language: 'fr',
  languageOptions: undefined,
  menu: [],
};

MenuMobile.propTypes = {
  design: PropTypes.object.isRequired,
  language: PropTypes.string,
  languageOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ),
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      path: PropTypes.string,
      color: PropTypes.string,
      translations: PropTypes.object,
    }),
  ),
  user: PropTypes.shape(userProptypes).isRequired,
};

export default MenuMobile;

/* eslint-disable @typescript-eslint/no-explicit-any */
import partition from 'lodash/partition';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import accommodationService from '../../../../core/services/accommodation.service';
import { useMe } from '../../../../profile/hooks';
import {
  AccommodationRoomStatus,
  AccommodationRoomUserStatus,
  RoomingSession,
  RoomType,
} from '../types';
import { getUserFullname } from '../utils';

const translationPrefix = 'blocks.rooming';

export function useRoomsForUser(
  collection: string,
  accommodationId: string,
  userId: string,
): Record<string, any> {
  const { data, refetch } = useQuery(
    ['accommodation-rooms', collection, accommodationId, userId],
    () => {
      return accommodationService.fetchUserRooms(collection, accommodationId, userId);
    },
    { refetchOnWindowFocus: true, enabled: !!accommodationId },
  );
  const [invitations, requests] = partition(data, (room) => room.owner?._id === userId);
  return { invitations, requests, refresh: refetch };
}

export function useRoomCompletLevelName(room: RoomType, roomingSession: RoomingSession): string {
  const { levelId } = room;
  // const levels = useRoomingLevels(collection, accommodationId);
  const { accommodationLevels } = roomingSession;
  const level = accommodationLevels?.find((lev) => lev._id === levelId);
  return level?.name || '';
}

export function useRoomHeader(
  room: RoomType,
  roomingSession: RoomingSession,
  usersById: Record<string, any>,
): string {
  const { t } = useTranslation();
  const me = useMe();
  const category = useRoomCompletLevelName(room, roomingSession);
  return useMemo(() => {
    const { status: roomStatus, owner, users = [] } = room;
    const isOwner = me._id === owner?._id;
    const inviter = getUserFullname(owner?._id, usersById);
    const { status: userStatus } = users.find((u: any) => u.userId === me._id) || {};
    if (roomStatus !== AccommodationRoomStatus.PENDING) {
      switch (roomStatus) {
        case AccommodationRoomStatus.VALID:
          return t(`${translationPrefix}.room-has-been-validated`, { room, category });
        case AccommodationRoomStatus.REJECTED:
          if (userStatus === 'REJECTED')
            return t(`${translationPrefix}.room-has-been-rejected-by-you`);
          return t(`${translationPrefix}.room-has-been-rejected-by-another-person`);
        case AccommodationRoomStatus.QUOTA_ERROR:
          return t(`${translationPrefix}.room-quota-reached`, { room, category });
        default:
          return '';
      }
    }
    // room pending
    if (!isOwner) {
      if (userStatus === AccommodationRoomUserStatus.PENDING)
        return t(`${translationPrefix}.you-have-been-invited-to-share-a-room-with`, {
          room,
          category,
          owner: inviter,
        });
      // other user's have not yet validated the room
      return t(`${translationPrefix}.you-have-already-validated-the-room`, {
        room,
        category,
        owner: inviter,
      });
    }
    return t(`${translationPrefix}.you-have-invited-persons`, {
      count: users.length - 1,
      room,
      category,
    });
  }, [category, me._id, room, t, usersById]);
}

/* eslint-disable operator-linebreak */
import { useUnmount } from 'ahooks';
import { isNaN } from 'lodash';
import { useMemo, useRef, useState } from 'react';

import useEvent from '../../../core/hooks/useEvent.hook';
import { uuid } from '../../../core/trackers/utils';

const REFRESH_TIME_MS = 30 * 1000;

type VideoSection = {
  id: string;
  startTime: number;
  endTime: number;
  watchDuration: number;
  hasEnded?: true;
};

type VideoStat = {
  item?: any;
  videoSessionId: string;
  startDate: string;
  endDate: string;
  video: {
    url: string;
    duration?: number;
  };
  videoSection: {
    id: string;
    startTime: number;
    endTime: number;
    watchDuration: number;
    hasEnded?: true;
  };
};

function updateVideoSection(sectionStat: VideoStat, videoSectionPatch: Partial<VideoSection>) {
  const { videoSection } = sectionStat;
  return {
    ...sectionStat,
    endDate: new Date().toISOString(),
    videoSection: {
      ...videoSection,
      ...videoSectionPatch,
      watchDuration: videoSectionPatch.endTime
        ? videoSectionPatch.endTime - videoSection.startTime
        : videoSection.watchDuration,
    },
  };
}

export function useVideoSectionStats(
  url: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  extraStats: any,
  onStats: (stats: VideoStat) => void,
): {
  notify: () => void;
  flush: () => void;
  play: (currentTime: number, duration: number) => void;
  stop: (currentTime: number, hasEnded: boolean) => void;
  updatetime: (currentTime: number, duration: number) => void;
} {
  const [videoSessionId] = useState(() => uuid());
  const lastFlushTimeRef = useRef(Date.now());
  const sectionStatRef = useRef<VideoStat | null>(null);

  const notify: () => void = useEvent(() => {
    lastFlushTimeRef.current = Date.now();
    if (onStats && sectionStatRef.current) {
      onStats(sectionStatRef.current);
    }
  });
  const flush: () => void = useEvent(() => {
    notify();
    sectionStatRef.current = null;
    lastFlushTimeRef.current = Date.now();
  });
  const play: (currentTime: number, duration: number) => void = useEvent(
    (currentTime: number, duration: number) => {
      flush();
      sectionStatRef.current = {
        ...extraStats,
        video: {
          url,
          duration: duration && !isNaN(duration) ? duration : undefined,
        },
        videoSessionId,

        startDate: new Date().toISOString(),
        endDate: new Date().toISOString(),

        videoSection: {
          id: uuid(),
          startTime: currentTime, // Seconds
          endTime: currentTime, // Seconds
          watchDuration: 0,
        },
      };
      notify();
    },
  );
  const stop: (currentTime: number, hasEnded: boolean) => void = useEvent(
    (currentTime: number, hasEnded: boolean) => {
      if (sectionStatRef.current) {
        sectionStatRef.current = updateVideoSection(sectionStatRef.current, {
          endTime: currentTime,
          hasEnded: hasEnded || undefined,
        });
        flush();
      }
    },
  );
  const updatetime: (currentTime: number, duration: number) => void = useEvent(
    (currentTime: number, duration: number) => {
      if (sectionStatRef.current) {
        // Check to see if has moved around too much (10s)
        const { endTime } = sectionStatRef.current.videoSection;
        if (currentTime < endTime - 1 || currentTime >= endTime + 10) {
          if (sectionStatRef.current) {
            // Currently playing... treat as new part
            play(currentTime, duration);
          }
        } else {
          sectionStatRef.current = updateVideoSection(sectionStatRef.current, {
            endTime: currentTime,
          });
          if (Date.now() - lastFlushTimeRef.current > REFRESH_TIME_MS) {
            notify();
          }
        }
      }
    },
  );

  useUnmount(() => {
    // Flush stats
    flush();
  });
  return useMemo(
    () => ({
      notify,
      flush,
      play,
      stop,
      updatetime,
    }),
    [notify, flush, play, stop, updatetime],
  );
}

import { useMount } from 'ahooks';
import keyBy from 'lodash/keyBy';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useMe } from '../../profile/hooks';
import store from '../../shared/Store';
import { fetchAchievements, fetchUserAchievements } from './achievements.actions';

export function useQueryAchievements() {
  const me = useMe();
  useMount(() => {
    store.reduxStore.dispatch(fetchAchievements());
    store.reduxStore.dispatch(fetchUserAchievements(me._id));
  });
}

export function useAchievements() {
  return useSelector((state) => ({
    achievements: state.achievements.achievements,
    categories: state.achievements.categories,
  }));
}

export function useUserAchievements() {
  return useSelector((state) => {
    const { userAchievements } = state.achievements;
    return {
      userAchievements,
      userAchievementsByAchievementId: keyBy(userAchievements, 'achievementId'),
    };
  });
}

export function useSelectedAchievements(achievementsIds) {
  const achievements = useSelector((state) => state.achievements.achievements);
  return useMemo(() => {
    const achievementsById = keyBy(achievements, '_id');
    return achievementsIds?.map((id) => achievementsById[id]).filter((v) => v);
  }, [achievements, achievementsIds]);
}

import React from 'react';
import { Header, Icon } from 'semantic-ui-react';
import { bem } from '../../../core/design/bem';
import { userArrayProptypes } from '../../../propTypes';
import SquareImage from '../../SquareImage';
import { getAvatarTitle } from './SimpleVariant';

const css = bem('AvatarsBlock--square');

const SquareVariant = (props) => {
  const { users, ...rest } = props;
  const {
    defaultThumbnail,
    imageSize = 80,
    template,
    showMailBubble,
    speakerThumbnailOptions = {},
  } = rest;
  return (
    <div className={css()}>
      {users.map((user, index) => {
        const { _id, thumbnail, firstName, lastName, email, company, role } = user;
        return (
          <div key={_id || index} className={css('avatar')}>
            {(thumbnail || defaultThumbnail) && (
              <div style={{ position: 'relative' }}>
                <SquareImage
                  as="img"
                  alt={`${firstName} ${lastName}`}
                  src={thumbnail || defaultThumbnail}
                  imageSize={imageSize}
                  cdnOptions={speakerThumbnailOptions}
                  className="thumbnail"
                />
                {showMailBubble && email && (
                  <a
                    className="email bubble"
                    href={`mailto:${email}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon name="envelope outline" />
                  </a>
                )}
              </div>
            )}
            <div className={css('content')}>
              <Header as="h4" className="fullname">
                {getAvatarTitle(user, template)}
              </Header>
              {company && <span>{company}</span>}
              {role && <span className="role">{role} </span>}
            </div>
          </div>
        );
      })}
    </div>
  );
};

SquareVariant.propTypes = { users: userArrayProptypes };
SquareVariant.defaultProps = { users: [] };

export default SquareVariant;

import { useMount } from 'ahooks';
import cx from 'classnames';
import get from 'lodash/get';
import keys from 'lodash/keys';
import PropTypes from 'prop-types';
import React from 'react';
import { Container, Grid, Pagination } from 'semantic-ui-react';
import UserAgendaBlock from '../../agenda/blocks/UserAgendaBlock';
import { fetchAgenda } from '../../agenda/store/agenda.actions';
import { CmsPageLayout } from '../../cms/CmsPage';
import { CmsBlocks } from '../../cms/CmsScreen';
import CdnImage from '../../components/CdnImage';
import Footer from '../../components/Footer';
import NotFoundBlock from '../../components/NotFoundBlock';
import PageBackground from '../../components/PageBackground';
import ScreenIntro from '../../components/screens/ScreenIntro';
import SidebarBlocks from '../../components/SidebarBlocks';
import TopMenu from '../../components/TopMenu';
import WorkshopList, { filterRegistered } from '../../components/workshops/list/WorkshopList';
import { useConfig } from '../../config/config.context';
import { useDesignConfig } from '../../config/design.context';
import { HorizontalMenu } from '../../Layouts/Default/components/HorizontalMenu';
import { imageProptypes } from '../../propTypes';
import store from '../../shared/Store';
import { fetchAppointments } from '../../store/actions';
import Styles from '../../Styles';
import { getString, throttleDispatchFetch } from '../../utils';
import { useFilter, useOrderBy, usePagination, usePreFilter } from '../../utils/hooks';
import {
  useGroupWorkshopsWithSessions,
  useSyncedCollectionWorkshopSessions,
} from '../../workshop-session/store/workshopSessions.hooks';
import WorkshopTable from '../components/WorkshopTable/WorkshopTable';
import { fetchWorkshops } from '../store/workshops.actions';
import { useSyncedCollectionWorkshops } from '../store/workshops.hooks';
import { MenuFilterBlock } from '../Workshops';
import WorkshopsWithRegistrationBlock from '../../home/blocks/WorkshopsWithRegistrationBlock';
import { withScreenClosed } from '../../core/screens/screens.hoc';
import { useRegistrationsById } from '../utils/workshopRegistrationUtils';

const translationPrefix = 'workshops';

const defaultOrder = [{ field: 'startDate', order: 'asc' }];
const defaultGroupBy = { field: 'startDate', type: 'date' };

const defaultLeftSidebar = { width: 4, blocks: [{ _id: 'filters', type: 'filters' }] };
const defaultRightSidebar = undefined;

const throttledFetch = throttleDispatchFetch(() => store.reduxStore.dispatch(fetchWorkshops()));

const headerProps = {
  backgroundColor: PropTypes.string,
  logo: imageProptypes,
};

const sidebarBlockComponents = {
  filters: MenuFilterBlock,
  userAgenda: UserAgendaBlock,
};

const PageHeader = ({ logo, backgroundColor }) => {
  return (
    <div className="header--fixed" style={{ backgroundColor }}>
      <div className="container">
        {logo && <CdnImage className="logo" maxHeight={100} src={logo} as="img" />}
      </div>
    </div>
  );
};

PageHeader.defaultProps = {
  logo: undefined,
  backgroundColor: undefined,
};
PageHeader.propTypes = headerProps;

export const PageLayout = ({ className, header, designOverride, children }) => {
  const design = useDesignConfig();
  const { menu, menus } = useConfig();
  const hasHeader = !!header;
  const { background } = designOverride; // page design
  const { color: backgroundColor, image: backgroundImage } = background || {};
  return (
    <div className={cx(className, { headered: hasHeader })}>
      {designOverride && <Styles designOverride={designOverride} />}
      <PageBackground
        {...background}
        image={backgroundImage || (!backgroundColor && design.homeBackground)}
      />
      {header && <PageHeader {...header} />}
      <Container>
        <div style={{ paddingBottom: 10 }}>
          {menu && <HorizontalMenu menu={menu} menus={menus} />}
        </div>
        {children}
      </Container>
      <Footer />
    </div>
  );
};

PageLayout.defaultProps = {
  className: '',
  designOverride: {},
  header: undefined,
};

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  designOverride: PropTypes.object,
  header: PropTypes.object,
};

const components = {
  'grid': WorkshopList,
  'table': WorkshopTable,
  'with-registration': WorkshopsWithRegistrationBlock,
};

function WorkshopsPage(props) {
  const { header, design, collection, pageId, mode, match, metadata, ...screenConfig } = props;
  const { matchParam } = screenConfig || {};
  const pageMatchParam = matchParam || 'category';
  const category = get(match.params, pageMatchParam);

  const {
    pageHeader,
    template,
    filters: fieldsToFilter = [],
    filterConfig = {},
    preFilters,
    itemProps,
    orderBy: orderByConfig = defaultOrder,
    groupBy = defaultGroupBy,
    leftSidebar = defaultLeftSidebar,
    rightSidebar = defaultRightSidebar,
    footer,
    pageFooter,
    showAllWorkshopsAllTime = false,
    showOnlyRegistered = false,
    pagination,
    topMenu,
    hasSessions,
  } = screenConfig;

  // Always fetch, or make it lazy ?
  useMount(() => {
    throttledFetch();
    store.reduxStore.dispatch(fetchAppointments());
    store.reduxStore.dispatch(fetchAgenda());
  });

  let workshops = useSyncedCollectionWorkshops(collection || pageId);
  const sessions = useSyncedCollectionWorkshopSessions(collection || pageId); // TODO: filter by collection

  workshops = useGroupWorkshopsWithSessions(workshops, hasSessions ? sessions : undefined);

  // Pre-filter
  const prefilteredData = usePreFilter(workshops, preFilters);

  // Only keep non-empty filters
  const [filteredData, allFilters, setFilters] = useFilter(prefilteredData, fieldsToFilter, {
    collection: collection || pageId,
    [pageMatchParam]: category,
  });

  const registrations = useRegistrationsById(hasSessions);
  const filteredWorkshops = filterRegistered(
    showAllWorkshopsAllTime ? prefilteredData : filteredData,
    registrations,
    showOnlyRegistered,
  );
  const finalWorkshops = useOrderBy(filteredWorkshops, orderByConfig);
  const sharedSidebarProps = {
    prefilteredData,
    fieldsToFilter,
    allFilters,
    setFilters,
    filterConfig,
    pageId,
  };
  const { maxItems, showPreviousAndNextNav } = pagination || {};
  const WorkshopsComponent = get(components, mode, WorkshopList);
  const { pageItems, setActivePage, totalPages } = usePagination(finalWorkshops, maxItems);

  return (
    <CmsPageLayout
      className={cx('page', 'page--workshops', pageId)}
      header={header}
      metadata={metadata}
      designOverride={design}
      pageHeader={pageHeader}
      pageFooter={pageFooter}
    >
      <Container>
        <TopMenu menu={topMenu} sharedProps={sharedSidebarProps} />

        <Grid columns="equal" stackable>
          <SidebarBlocks
            className="sidebar--left"
            sidebar={leftSidebar}
            blockComponents={sidebarBlockComponents}
            sharedProps={sharedSidebarProps}
          />
          <Grid.Column>
            <ScreenIntro type="workshops" category={pageId} />
            <WorkshopsComponent
              filteredWorkshopIds={
                keys(allFilters)?.length > 1 ? filteredData?.map((w) => w._id) : undefined
              }
              workshopList={pageItems}
              groupBy={groupBy}
              template={template}
              itemProps={{ ...itemProps, hasSessions }}
              hasSessions={hasSessions}
              showOnlyRegistered={showOnlyRegistered}
            />
            {pageItems.length === 0 && (
              <NotFoundBlock {...getString(`${translationPrefix}.not-found`)} />
            )}
            {maxItems && totalPages > 1 && (
              <div style={{ textAlign: 'center', marginTop: 15 }}>
                <Pagination
                  className="paginator"
                  boundaryRange={0}
                  ellipsisItem={null}
                  firstItem={null}
                  lastItem={null}
                  prevItem={showPreviousAndNextNav ? undefined : null}
                  nextItem={showPreviousAndNextNav ? undefined : null}
                  siblingRange={2}
                  defaultActivePage={1}
                  totalPages={totalPages}
                  pointing
                  secondary
                  {...pagination}
                  onPageChange={(_e, data) => {
                    const { activePage: i } = data;
                    setActivePage(i);
                  }}
                />
              </div>
            )}
          </Grid.Column>
          <SidebarBlocks
            sidebar={rightSidebar}
            blockComponents={sidebarBlockComponents}
            sharedProps={sharedSidebarProps}
          />
        </Grid>
        {footer && <CmsBlocks blocks={footer.blocks} />}
      </Container>
    </CmsPageLayout>
  );
}

WorkshopsPage.defaultProps = {
  collection: undefined,
  design: {},
  header: {},
  metadata: undefined,
  mode: 'grid',
};

WorkshopsPage.propTypes = {
  collection: PropTypes.string,
  design: PropTypes.object,
  header: PropTypes.object,
  match: PropTypes.object.isRequired,
  metadata: PropTypes.object,
  mode: PropTypes.oneOf(['grid', 'list']),
  pageId: PropTypes.string.isRequired,
};

export default withScreenClosed(WorkshopsPage, { className: 'page--workshops' });

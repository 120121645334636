/* eslint-disable react/no-danger */
import React from 'react';
import cx from 'classnames';
import { Form, Header, Segment } from 'semantic-ui-react';
import { LoginBanner, LoginFooter, LoginFullScreenContainer } from './components';
import ExpiringNavLink from '../../../components/ExpiringNavLink';
import PageBackground from '../../../components/PageBackground';
import { useConfig } from '../../../config/config.context';
import { useDesignConfig } from '../../../config/design.context';
import CdnImage from '../../../components/CdnImage';
import { getString } from '../../../utils';

const translationPrefix = 'login';

type Props = React.PropsWithChildren<{
  error?: any;
  screen?: any;
  sideSection?: any;
}>;

const ModalLoginLayout = ({ children, error, screen, sideSection }: Props): JSX.Element => {
  const {
    loginBackground,
    loginBackgroundVideo,
    loginLogo,
    loginLogoProps,
    primaryColor: color,
  } = useDesignConfig();
  const config = useConfig();
  const { isPlatformClosed } = config;

  return (
    <>
      <PageBackground image={loginBackground} video={loginBackgroundVideo} />
      <LoginFullScreenContainer
        className={cx(
          'screen-auth',
          screen && `screen-auth--${screen}`,
          // TODO use bem for className with variant
          'screen-auth--variant-modal',
        )}
        sideSection={isPlatformClosed ? undefined : sideSection}
      >
        {loginLogo && (
          <ExpiringNavLink to="/">
            <CdnImage
              className="authentication--logo"
              as="img"
              src={loginLogo}
              maxWidth={422}
              maxHeight={100}
              alt="event logo"
              {...loginLogoProps}
            />
          </ExpiringNavLink>
        )}
        <p className="authentication--welcome">
          {getString(`${translationPrefix}.welcome-to-the-platform`)}
        </p>
        <Form size="large" error={!!error}>
          <Segment className="authentication--container">
            <LoginBanner />
            {isPlatformClosed && (
              <div className="platform-closed">
                <Header as="h2" style={{ color }}>
                  {getString(`${translationPrefix}.platform-closed-title`)}
                </Header>
                <p
                  dangerouslySetInnerHTML={{
                    __html: getString(`${translationPrefix}.platform-closed-content`),
                  }}
                />
              </div>
            )}
            {!isPlatformClosed && children}
          </Segment>
        </Form>
        <LoginFooter />
      </LoginFullScreenContainer>
    </>
  );
};

export default ModalLoginLayout;

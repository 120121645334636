import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'semantic-ui-react';
import { useEntityTags } from '../../../../utils/hooks';

const EntityTags = ({ entity, type, tagFields }) => {
  const tags = useEntityTags(entity, type, tagFields);
  if (!tags || !tags.length) return null;
  return (
    <div className="tags">
      {tags.map((tag) => (
        <Label className="tags--tag" key={tag}>
          {tag}
        </Label>
      ))}
    </div>
  );
};

EntityTags.defaultProps = {
  tagFields: [],
};

EntityTags.propTypes = {
  entity: PropTypes.object.isRequired,
  tagFields: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string.isRequired,
};

export default EntityTags;

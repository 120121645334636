import React from 'react';
import { bem } from '../../core/design/bem';
import SquareImage from '../../components/SquareImage';
import HoverTile from './HoverTile';
import { Likes, SocialWallMessage } from '../types/SocialWallTypes';

const css = bem('Grid');

const TileDefaultProps = {
  likes: {},
};

type TileProps = {
  item: SocialWallMessage;
  likes?: Likes;
  openLightbox: (id: string) => void;
} & typeof TileDefaultProps;

const ImageTile = ({ item, likes, openLightbox }: TileProps): JSX.Element | null => {
  return (
    <div
      role="button"
      tabIndex={0}
      className={css('tile')}
      onClick={() => {
        openLightbox(item.id);
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          openLightbox(item.id);
        }
      }}
    >
      <HoverTile item={item} likes={likes} />
      <div className={css('message')}>
        <SquareImage src={item.image} imageSize={500} srcSet />
      </div>
    </div>
  );
};

ImageTile.defaultProps = TileDefaultProps;

export default ImageTile;

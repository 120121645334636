import { useEffect } from 'react';
import PubsubService, { Subscription } from '../services/pubsub.service';

export const useSubscriptions = (subscriptions: Subscription[], enabled: boolean): any => {
  useEffect(() => {
    if (!enabled) {
      return undefined;
    }

    PubsubService.subscribe(subscriptions);
    return () => PubsubService.unsubscribe(subscriptions);
  }, [enabled, subscriptions]);
};

export const useSubscription = (subscription: Subscription, enabled: boolean): any =>
  useSubscriptions([subscription], enabled);

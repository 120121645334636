/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { Button, Card, Container, Grid, Icon } from 'semantic-ui-react';
import BlockContainer from '../components/BlockContainer';
import SpeakerHeader from '../components/speakers/SpeakerHeader';
import VideoCard from '../components/video/VideoCard';
import WorkshopsMatchingBlock from '../components/workshops/WorkshopsMatchingBlock';
import { useScreenConfig } from '../config/screens.context';
import { useSpeakers } from '../core/users/users.hooks';
import { containerTypePropTypes } from '../propTypes';
import { CmsPageLayout } from '../cms/CmsPage';

const blockTranslationPrefix = 'blocks';

const VideosBlock = ({ videos, container }) => {
  const { t } = useTranslation();
  const visibleVideos = videos.filter((video) => video.file || video.value);
  if (visibleVideos.length === 0) return null;

  return (
    <BlockContainer
      {...container}
      header={container?.header || t(`${blockTranslationPrefix}.videos`)}
    >
      <Card.Group centered>
        {visibleVideos.map((video, index) => {
          // eslint-disable-next-line react/no-array-index-key
          return <VideoCard key={index} video={video} />;
        })}
      </Card.Group>
    </BlockContainer>
  );
};
VideosBlock.defaultProps = {
  container: undefined,
  videos: [],
};
VideosBlock.propTypes = {
  container: containerTypePropTypes,
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.sting,
    }),
  ),
};

const videoContainer = { type: 'basic' };

const Speaker = ({ match, pageId }) => {
  const {
    showSessions = true,
    workshopsBlock,
    variant,
    sessionMatchingFields = ['speakers'],
    ...rest
  } = useScreenConfig('speaker');
  const speakers = useSpeakers();
  const { id, slug } = match.params;
  const { t } = useTranslation();
  const translationPrefix = 'speakers.speaker';

  const idToMatch = id || slug;

  const speaker = speakers.find((s) => (s.slug ? s.slug === idToMatch : s._id === idToMatch));
  if (!speaker) return null;
  return (
    <CmsPageLayout className={cx('page', 'page--speakers', pageId || 'speaker')} {...rest}>
      <Container>
        <div className={`screen--type-speaker speaker--${speaker._id}`}>
          <Button
            className="back-button"
            style={{ backgroundColor: 'transparent', paddingLeft: 0, color: 'white' }}
            as={Link}
            to="/speakers"
          >
            <Icon name="angle left" /> {t(`${translationPrefix}.back-to-speakers`)}
          </Button>
          <Grid>
            <Grid.Column>
              <SpeakerHeader fluid speaker={speaker} variant={variant} />
              {showSessions &&
                sessionMatchingFields.map((field) => (
                  <WorkshopsMatchingBlock
                    key={field}
                    field={field}
                    value={speaker._id}
                    itemProps={workshopsBlock}
                  />
                ))}
              <VideosBlock videos={speaker.videos} container={videoContainer} />
            </Grid.Column>
          </Grid>
        </div>
      </Container>
    </CmsPageLayout>
  );
};

Speaker.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      slug: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default Speaker;

import { useLocalStorageState } from 'ahooks';
import React from 'react';
import { Modal } from 'semantic-ui-react';
import { useDesignConfig } from '../../config/design.context';
import { bem } from '../../core/design/bem';
import CdnImage from '../CdnImage';

const css = bem('WelcomePopup');

const { eventId } = window.__DATA__;

const WelcomePopup = (): JSX.Element | null => {
  const { welcomePopup } = useDesignConfig();
  // TODO: for mobile apps ?
  const [lastPopup, setLastPopup] = useLocalStorageState(`platform-${eventId}-welcomePopup`, '');
  if (!welcomePopup?.uri || lastPopup === welcomePopup?.uri) {
    return null;
  }

  const handleClose = () => setLastPopup(welcomePopup.uri);

  return (
    <Modal open basic className={css.toString()} onClose={handleClose}>
      <Modal.Content>
        <CdnImage src={welcomePopup} onClick={handleClose} />
      </Modal.Content>
    </Modal>
  );
};

export default WelcomePopup;

import { useRequest } from "ahooks";
import orderBy from "lodash/orderBy";
import notificationsService from "../core/services/notifications.service";

const useNotifications = (): any => {
  const { data: notifications, loading, refresh } = useRequest(() => notificationsService.getNotifications(), {
    pollingInterval: 10000,
    cacheKey: 'notifications',
  });
  return {
    notifications: orderBy(notifications, 'createdAt', 'desc'), loading, refresh
  }
}

export {
  useNotifications
}

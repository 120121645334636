import { useMemo } from 'react';
import { useSubscription } from '../../pubsub/hooks/pubsub.hook';
import store from '../../shared/Store';
import { useMe } from '../../profile/hooks';
import { useConfig } from '../../config/config.context';

export const useGamification = (callback: (type: string, message: any) => void): any => {
  const me = useMe();
  const config = useConfig();
  const subscription = useMemo(
    () => ({
      topic: `/${store.eventId}/gamification/users/${me._id}`,
      type: 'gamification:achievement:unlock',
      callback,
    }),
    [me._id, callback],
  );
  return useSubscription(subscription, config?.gamification?.enabled);
};

import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Grid } from 'semantic-ui-react';

import UserAgendaBlock from '../agenda/blocks/UserAgendaBlock';
import { CmsPageLayout } from '../cms/CmsPage';
import { CmsBlocks } from '../cms/CmsScreen';
import LightBackButton from '../components/BackButton/LightBackButton';
import NotFoundBlock from '../components/NotFoundBlock';
import SidebarBlocks from '../components/SidebarBlocks';
import { bem } from '../core/design/bem';
import { cmsBlocksType, orderByType } from '../core/platformPropTypes';
import { selectUsers } from '../core/users/users.select';
import { fetchUserCollections } from '../core/users/users.utils';
import { getStrings, throttleDispatchFetch } from '../utils';
import { useFilter, useOrderBy, usePreFilter } from '../utils/hooks';
import { MenuFilterBlock } from '../workshops/Workshops';
import UserPreviewBlock from './blocks/UserPreviewBlock';
import ParticipantsRgpdBlock from './components/ParticipantsRgpdBlock';
import UserGrid from './components/UserGrid';

const pageBlock = bem('page');

const translationPrefix = 'participants';

const sidebarBlockComponents = {
  filters: MenuFilterBlock,
  userAgenda: UserAgendaBlock,
  userPreview: UserPreviewBlock,
};

const throttledFetch = throttleDispatchFetch(fetchUserCollections);

const defaultConfig = {
  order: [
    { field: 'lastName', order: 'asc' },
    { field: 'firstName', order: 'asc' },
  ],
  leftSidebar: { width: 4, blocks: [{ _id: 'filters', type: 'filters' }] },
  rightSidebar: undefined, // { width: 3, blocks: [{ _id: 'profile', type: 'userProfile' }] };
  collections: ['participants'],
};

const ParticipantsScreen = (props) => {
  const {
    itemConfig,
    filters: fieldsToFilter,
    orderBy: orderByConfig,
    leftSidebar,
    rightSidebar,
    collections,
    preFilters,
    rgpdValidationBlock,
    header,
    footer,
    design,
    pageHeader,
    pageFooter,
    metadata,
    showGoBack,
    pageId,
  } = props;
  const { users: allUsers = [], loaded } = useSelector((state) => selectUsers(state, collections));
  useEffect(() => {
    throttledFetch(collections);
  }, [collections]);

  const users = usePreFilter(allUsers, preFilters);

  const data = users;
  const [filteredData, allFilters, setFilters] = useFilter(data, fieldsToFilter);
  const results = useOrderBy(filteredData, orderByConfig);
  const sharedSidebarProps = {
    prefilteredData: users,
    data,
    fieldsToFilter,
    allFilters,
    setFilters,
  };

  if (!loaded) return null;
  return (
    <CmsPageLayout
      designOverride={design}
      pageHeader={pageHeader}
      pageFooter={pageFooter}
      metadata={metadata}
      className={pageBlock({ participants: true, [pageId]: true }).toString()}
    >
      {header && <CmsBlocks blocks={header.blocks} />}
      <Container>
        <LightBackButton showGoBack={showGoBack} />
        <Grid columns="equal" stackable>
          <SidebarBlocks
            sidebar={leftSidebar}
            blockComponents={sidebarBlockComponents}
            sharedProps={sharedSidebarProps}
          />
          <Grid.Column>
            {!!rgpdValidationBlock?.field && <ParticipantsRgpdBlock {...rgpdValidationBlock} />}
            <UserGrid className="participants-grid" items={results} {...itemConfig} />
            {results.length === 0 && (
              <NotFoundBlock {...getStrings(`${translationPrefix}.not-found`)} />
            )}
          </Grid.Column>
          <SidebarBlocks
            sidebar={rightSidebar}
            blockComponents={sidebarBlockComponents}
            sharedProps={sharedSidebarProps}
          />
        </Grid>
      </Container>
      {footer && <CmsBlocks blocks={footer.blocks} />}
    </CmsPageLayout>
  );
};

ParticipantsScreen.defaultProps = {
  collections: defaultConfig.collections,
  filters: [],
  design: undefined,
  footer: undefined,
  groupBy: undefined,
  header: undefined,
  itemConfig: {},
  leftSidebar: defaultConfig.leftSidebar,
  metadata: undefined,
  orderBy: defaultConfig.order,
  pageFooter: undefined,
  pageHeader: undefined,
  showGoBack: undefined,
  rightSidebar: defaultConfig.rightSidebar,
};

ParticipantsScreen.propTypes = {
  collections: PropTypes.arrayOf(PropTypes.string),
  design: PropTypes.object,
  filters: PropTypes.array,
  footer: cmsBlocksType,
  groupBy: PropTypes.object,
  header: cmsBlocksType,
  itemConfig: PropTypes.object,
  leftSidebar: PropTypes.object,
  metadata: PropTypes.object,
  showGoBack: PropTypes.bool,
  orderBy: orderByType,
  pageId: PropTypes.string.isRequired,
  pageFooter: PropTypes.object,
  pageHeader: PropTypes.object,
  rightSidebar: PropTypes.object,
};

export default ParticipantsScreen;

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable class-methods-use-this */
import { RoomingSession } from '../../components/cms/RoomingBlock/types';
import platformService from './platform.service';

const { endpoints } = window.__DATA__;

class AccommodationService {
  async addRoom(collection: string, accommodationId: string, body: Record<string, any>) {
    return platformService.securePost(
      `${endpoints.platform}/accommodations/collections/${collection}/${accommodationId}/rooms`,
      body,
    );
  }

  async deleteRoom(collection: string, roomId: string) {
    return platformService.secureDelete(
      `${endpoints.platform}/accommodations/collections/${collection}/rooms/${roomId}`,
    );
  }

  async fetchUserRooms(collection: string, accommodationId: string, userId: string) {
    return platformService.secureGet(
      `${endpoints.platform}/accommodations/collections/${collection}/${accommodationId}/users/${userId}/rooms`,
    );
  }

  async patchUserRoom(
    collection: string,
    accommodationId: string,
    roomId: string,
    roomUserId: string,
    patch: Record<string, any>,
  ) {
    return platformService.securePatch(
      `${endpoints.platform}/accommodations/collections/${collection}/${accommodationId}/rooms/${roomId}/users/${roomUserId}`,
      patch,
    );
  }

  async fetchRoomingSession(roomingSessionId: string, userId: string): Promise<RoomingSession> {
    return platformService
      .api()
      .url(`/roomings/${roomingSessionId}/users/${userId}/config`)
      .get()
      .json();
  }
}

export default new AccommodationService();

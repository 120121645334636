import values from 'lodash/values';
import React, { useEffect, useMemo } from 'react';
import { useMedia } from 'react-media';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { Button, Segment, Sidebar } from 'semantic-ui-react';
import Chat from '../../components/Chat/Chat';
import GlobalModalManager from '../../components/GlobalModalManager/GlobalModalManager';
import VideoChatModal from '../../components/VideoChatModal';
import { useScreenConfig } from '../../config/screens.context';
import meetingRoomService from '../../core/services/meetingRoom.service';
import { getString } from '../../utils';
import { GLOBAL_MEDIA_QUERIES } from '../../utils/mediaQueries';
import ChatList from '../blocks/ChatList';
import {
  deleteMessage,
  sendMessage,
  sendVisioEnded,
  sendVisioRequest,
  startNetworkingChat,
  stopNetworkingChat,
} from '../store/networking.actions';
import { activeChats, chatIdFromHash } from '../store/networking.selectors';
import './NetworkingChat.scss';

function ensureName(profile) {
  return {
    ...profile,
    name: `${profile.firstName} ${profile.lastName}`,
  };
}

function computeChatMessages(chat) {
  if (!chat) return [];

  const invitedProfile = ensureName(chat.invitedProfile);
  const inviterProfile = ensureName(chat.inviterProfile);

  const messages = (values(chat.messages) || []).map((message) => {
    const { emitter } = message;
    const profile = emitter === chat.invited ? invitedProfile : inviterProfile;
    return { ...message, profile };
  });
  // Inject initial message ?
  if (messages.length < 100) {
    messages.splice(0, 0, {
      text: chat.message,
      emitter: inviterProfile._id,
      profile: inviterProfile,
      createdAt: chat.createdAt,
    });
  }

  return messages;
}

const NetworkingChat = () => {
  const match = useRouteMatch('/networking/chats/:chatId');
  const { canDeleteMessage, hasVisioMeeting } = useScreenConfig('networking').chat ?? {};
  const selectedChatHash = match?.params?.chatId;

  const selectedChat = useSelector(chatIdFromHash(selectedChatHash));
  const dispatch = useDispatch();
  const chats = useSelector(activeChats);
  const me = useSelector((state) => state.user.user?._id);
  const matches = useMedia({
    queries: GLOBAL_MEDIA_QUERIES,
  });
  useEffect(() => {
    if (!selectedChat) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    }
    dispatch(startNetworkingChat(selectedChat));

    return () => {
      dispatch(stopNetworkingChat(selectedChat));
    };
  }, [selectedChat]);

  const currentChat = chats[selectedChat];
  const currentMessages = useMemo(() => computeChatMessages(currentChat), [currentChat]);
  const chatHeaderMessage = getString('networking.chat.headerMessage');

  async function handleEnterVisio(chat, visio) {
    await GlobalModalManager.open(VideoChatModal, { visio, chat });
    dispatch(sendVisioEnded(chat, visio));
  }

  async function handleStartVisio(chat) {
    // Fetch visio url
    const { success, session } = await meetingRoomService.createVisioRoom(me);
    if (success) {
      const visio = {
        type: 'vonage',
        account: 'meetings',
        ...session,
        state: 'started',
      };
      dispatch(sendVisioRequest(chat, visio));
      handleEnterVisio(chat, visio);
    }
  }

  return (
    <Sidebar.Pushable as={Segment} className="NetworkingChat--Container">
      <Sidebar
        className="NetworkingChat--Sidebar"
        animation="overlay"
        direction="left"
        width="wide"
        visible={!matches.mobile || !selectedChat}
      >
        <ChatList chats={chats} me={me} currentChat={currentChat} />
      </Sidebar>
      <Sidebar.Pusher className="NetworkingChat--ChatWrapper">
        <Button
          as={NavLink}
          className="NetworkingChat--SidebarTrigger"
          compact
          circular
          positive
          to="/networking/chats"
          icon="arrow left"
          aria-label="open chat sidebar"
        />
        {currentChat && (
          <Chat
            headerMessage={chatHeaderMessage && { text: chatHeaderMessage }}
            me={me}
            messages={currentMessages}
            onEnterVisio={(visio) => handleEnterVisio(currentChat, visio)}
            onStartVideoCall={hasVisioMeeting ? () => handleStartVisio(currentChat) : undefined}
            onDelete={canDeleteMessage ? (id) => dispatch(deleteMessage(currentChat, id)) : null}
            onSendMessage={(message) => dispatch(sendMessage(currentChat, message))}
          />
        )}
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
};

export default NetworkingChat;

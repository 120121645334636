/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useMemo } from 'react';
import orderBy from 'lodash/orderBy';
import cx from 'classnames';
import Collapsible from 'react-collapsible';
import { useTranslation } from 'react-i18next';
import { Header, Icon } from 'semantic-ui-react';
import { bem } from '../../../core/design/bem';
import BlockContainer from '../../BlockContainer';
import CdnImage from '../../CdnImage';
import { ContainerProps } from '../types';
import './ProgramBlock.scss';
import ImageIcon from '../../ImageIcon';

const css = bem('ProgramBlock');
const translationPrefix = 'blocks.program';

type ProgramItem = {
  _id: string;
  time?: string;
  title: string;
  subtitle?: string;
  icon?: string | Record<string, any>;
};

type Image = {
  uri: string;
} & Record<string, any>;

type Day = {
  _id: string;
  date: string;
  sideTitle: string;
  items: ProgramItem[];
  image?: Image;
};

type DayProgramProps = {
  program: Day;
  config?: Record<string, any>;
};

const DayProgram = (props: DayProgramProps): JSX.Element => {
  const { program, config = {} } = props;
  const { imageProps = {} } = config;
  const { _id, date, items = [], image, sideTitle = '' } = program;
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const dateLabel = t(`${translationPrefix}.date`, { date });
  const showDescription = items.length > 0 || !!image?.uri;
  return (
    <Collapsible
      open={isOpen}
      trigger={
        <div className={css('day', { _id })}>
          {!!sideTitle && (
            <Header as="h4" className="side">
              {sideTitle}
            </Header>
          )}
          <Header as="h3" className="date">
            {dateLabel}
          </Header>
          {showDescription && (
            <Icon className="chevron" name={!isOpen ? 'chevron down' : 'chevron up'} />
          )}
        </div>
      }
      transitionTime={200}
      onOpening={() => setIsOpen(true)}
      onClosing={() => setIsOpen(false)}
    >
      {showDescription && (
        <div className={css('details')}>
          <div className="items">
            {items.map(({ _id: itemId, title, icon, subtitle, time }) => (
              <div className={cx('item', itemId)} key={_id}>
                {icon && <ImageIcon icon={icon} className="icon" maxHeight={30} />}
                <div className="content">
                  {!!time && <div className="time">{time}</div>}
                  <Header as="h4" className="title">
                    {title}
                  </Header>
                  <div className="subtitle">{subtitle} </div>
                </div>
              </div>
            ))}
          </div>
          {image?.uri && (
            <div className="image">
              <CdnImage src={image} maxWidth={500} {...imageProps} />
            </div>
          )}
        </div>
      )}
    </Collapsible>
  );
};

DayProgram.defaultProps = {
  config: {},
};

type ProgramBlockProps = {
  container?: ContainerProps;
  days: Day[];
  config?: Record<string, any>;
};

const ProgramBlock = (props: ProgramBlockProps): JSX.Element => {
  const { container, days = [], config } = props;
  const filteredDays = useMemo(
    () =>
      orderBy(
        days.filter((d) => !!d.date),
        'date',
        'asc',
      ),
    [days],
  );
  return (
    <BlockContainer className={css()} {...container}>
      {filteredDays.map((program) => (
        <DayProgram key={program._id || program.date} program={program} config={config} />
      ))}
    </BlockContainer>
  );
};

ProgramBlock.defaultProps = {
  container: {},
  config: {},
};

export default ProgramBlock;

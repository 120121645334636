/* eslint-disable react/require-default-props */
/** @jsxImportSource @emotion/react */
import Contacts, { ContactsProps } from '../components/Contacts';
import { ContactsCSSProps, useContactsCSS } from '../hooks';
import { ContactsDesign } from '../types';
import './ContactsVariantBadge.scss';

function actionIconBackgroundColor({ actions, design }: ContactsCSSProps): string[] {
  return (actions || []).map(
    ({ _id, color }) => `
.Action--id-${_id} .Action__Icon {
  background-color: ${color || design?.colors?.primary};
}`,
  );
}

const ContactsVariantBadge = (props: ContactsProps & { design: ContactsDesign }): JSX.Element => {
  const style = useContactsCSS(props, actionIconBackgroundColor);
  return <Contacts css={style} {...props} />;
};

export { ContactsVariantBadge };

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Form } from 'semantic-ui-react';
import { replaceValues } from '../../utils/stringUtils';

const FieldInput = ({ field, label, value, onChange, editable, error }) => {
  const { key, type = 'string', hidden = false, itemTemplate } = field;
  if (!editable && type === 'array') {
    if (itemTemplate) {
      return (
        <ul>
          {value.map((v) => {
            const finalString = replaceValues(itemTemplate, v);
            return <li>{finalString}</li>;
          })}
        </ul>
      );
    }
    return <>{value.join(', ')}</>;
  }
  if (!editable && !hidden) return <>{label}</>;
  if (!editable && hidden) return null;

  switch (type) {
    case 'bool': {
      return (
        <Form.Checkbox
          toggle
          checked={value || false}
          onChange={(_, { checked }) => onChange({ [key]: checked })}
        />
      );
    }
    case 'string':
    default: {
      return (
        <Form.Input
          value={value || ''}
          fluid
          error={error}
          onChange={(_, { value: v }) => onChange({ [key]: v })}
        />
      );
    }
  }
};

export default FieldInput;

/* eslint-disable @typescript-eslint/no-unused-vars,react/prop-types */
import React, { FC } from 'react';
import { Card, Container, Grid, Header } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { CmsPageLayout } from '../cms/CmsPage';
import { CmsBlocks } from '../cms/CmsScreen';

import { bem } from '../core/design/bem';
import Notification from './components/Notification';
import { withScreenClosed } from '../core/screens/screens.hoc';
import { useNotifications } from '../hooks/useNotifications';

const css = bem('page');

type NotificationsScreenProps = { [x: string]: Record<string, any> };

const NotificationsScreen: FC<NotificationsScreenProps> = (props: NotificationsScreenProps) => {
  const { header, footer, metadata, design, pageHeader, pageFooter } = props;
  const { notifications = [] } = useNotifications();
  const { t } = useTranslation();

  return (
    <CmsPageLayout
      className={css({
        notifications: true,
      }).toString()}
      metadata={metadata}
      designOverride={design}
      pageHeader={pageHeader}
      pageFooter={pageFooter}
    >
      <Container>
        {header && <CmsBlocks blocks={header.blocks} />}
        <div className={css('container')}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <Header as="h2" style={{ marginBottom: 0, color: 'white' }}>
              {t('notifications.title')}
            </Header>
          </div>

          <Card.Group
            style={{
              height: '100%',
              margin: 0,
            }}
          >
            {notifications.map((notification: any) => (
              <Notification {...notification} />
            ))}
          </Card.Group>
        </div>
        {footer && <CmsBlocks blocks={footer.blocks} />}
      </Container>
    </CmsPageLayout>
  );
};

NotificationsScreen.defaultProps = {};

export default withScreenClosed(NotificationsScreen, { className: 'page--notifications' });

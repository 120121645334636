import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'semantic-ui-react';
import { CmsPageLayout } from '../../cms/CmsPage';
import { bem } from '../../core/design/bem';
import { usePageWorkshop, WorkshopPageContent } from '../Workshop';

const css = bem('page');

const WorkshopPage = ({ pageId, ...screenConfig }) => {
  const { design, pageHeader, pageFooter, collection } = screenConfig;
  const workshop = usePageWorkshop(collection);
  return (
    <CmsPageLayout
      className={css({
        [pageId]: true,
        'workshop': true,
        'workshop-collection': workshop?.collection,
        'workshop-category': workshop?.category,
        'workshop-id': workshop?._id,
        [`workshop-${workshop?._id}`]: !!workshop?._id, // DEPRECATED
      }).toString()}
      designOverride={design}
      pageHeader={pageHeader}
      pageFooter={pageFooter}
    >
      <Container>
        <WorkshopPageContent {...screenConfig} pageId={pageId} workshop={workshop} />
      </Container>
    </CmsPageLayout>
  );
};

WorkshopPage.propTypes = {
  pageId: PropTypes.string.isRequired,
};

export default WorkshopPage;

/* eslint-disable @typescript-eslint/no-explicit-any */
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { FC } from 'react';
import { Card, Header } from 'semantic-ui-react';
import SpeakerAvatars from '../../../speakers/SpeakerAvatars';
import WorkshopDate from '../../WorkshopDate';
import { workshopProptypes } from '../../../../propTypes';
import Images from '../../../../utils/Images';
import { getClassName, getWorkshopString } from '../../../../workshops/utils';
import './WorkshopImageItem.scss';
import '../light-item/WorkshopLightItem.scss';
import { WorkshopActions } from '../actions/WorkshopActions';
import { computeActions } from '../actions/actions.utils';

type WorkshopImageItemProps = {
  workshop: any;
  config?: any;
};

const WorkshopImageItem: FC<WorkshopImageItemProps> = (props) => {
  const { workshop, config } = props;
  const { showSpeakers = true, showDate = true, showEndTime = false, speakerConfig = {} } =
    config || {};

  const { shortDescription, image, speakers, title, startDate, endDate, category } = workshop;

  return (
    <Card className={cx('Workshop', getClassName(workshop))}>
      <Card.Content className="Workshop__Container">
        {image && image.uri && (
          <div
            className="Workshop__Container--Image"
            style={{
              backgroundImage: `url(${Images.cdn(image)})`,
            }}
            title={title}
          />
        )}
        <div className="Workshop__Container--Content">
          <div style={{ flex: 1 }}>
            {title && (
              <Header className="title" as="h2">
                {title}
              </Header>
            )}
            {category && <p className="category">{getWorkshopString(workshop, 'category')}</p>}
            {showDate && (
              <Card.Description className="date">
                <WorkshopDate startDate={startDate} endDate={endDate} showEndTime={showEndTime} />
              </Card.Description>
            )}
            {shortDescription && (
              <Card.Description className="description">{shortDescription}</Card.Description>
            )}
            {showSpeakers && (
              <div className="speakers">
                <SpeakerAvatars size="mini" speakerIds={speakers} {...speakerConfig} />
              </div>
            )}
          </div>
          <WorkshopActions actions={computeActions(config)} workshop={workshop} />
        </div>
      </Card.Content>
    </Card>
  );
};

WorkshopImageItem.defaultProps = {
  config: {},
};

WorkshopImageItem.propTypes = {
  config: PropTypes.object,
  workshop: PropTypes.shape(workshopProptypes).isRequired,
};

export default WorkshopImageItem;

/* eslint-disable react/no-danger */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from 'semantic-ui-react';
import { bem } from '../../../../core/design/bem';
import OptLink from '../../../../OptLink';

const css = bem('CarouselBlock');

const translationPrefix = 'blocks.carousel.text';

type TextVariantProps = {
  description?: string;
  title?: string;
  link?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
};

const TextVariant = ({
  title,
  description,
  link,
  ...rest
}: TextVariantProps): JSX.Element | null => {
  const { t } = useTranslation();
  const { style = {} } = rest;
  return (
    <div className={css('text')} style={style}>
      <Header as="h3" className="title">
        <div dangerouslySetInnerHTML={{ __html: title || '' }} />
      </Header>
      {description && (
        <div className="description">
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      )}
      {link && (
        <div className="read-more">
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-expect-error */}
          <OptLink to={link} className="btn">
            {t(`${translationPrefix}.read-more`)}
          </OptLink>
        </div>
      )}
    </div>
  );
};

TextVariant.defaultProps = {
  description: undefined,
  title: undefined,
  link: undefined,
};

export default TextVariant;

import React, { FC } from 'react';
import { Card } from 'semantic-ui-react';

import './Notification.scss';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';

type NotificationProps = {
  id: string;
  kind: string;
  date?: string;
  data?: {
    body: string;
    title: string;
  };
  createdAt: string;
};

const getKindLabel = (kind: string, t: any) => {
  switch (kind) {
    case 'reminder':
      return t('notifications.kind.reminder');
    default:
      return t('notifications.kind.push');
  }
};

const Notification: FC<NotificationProps> = (props) => {
  const { data, createdAt, kind } = props;
  const { t } = useTranslation();
  const { body, title } = data || {};
  return (
    <Card
      fluid
      className="notification"
      style={{
        backgroundColor: `#6A99F815`,
        borderLeft: `solid 0.24em  #6A99F8`,
      }}
    >
      <Card.Content className="content" style={{ color: 'white' }}>
        <div className="type" style={{ color: '#6A99F8' }}>
          {getKindLabel(kind, t)}
        </div>
        <Card.Header className="title">{title}</Card.Header>
        {body && <Card.Description className="description">{body}</Card.Description>}
        {createdAt && (
          <Card.Meta className="date" style={{ color: '#6B6B6B' }}>
            {moment(createdAt).fromNow()}
          </Card.Meta>
        )}
      </Card.Content>
    </Card>
  );
};

Notification.defaultProps = {};

export default Notification;

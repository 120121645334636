/* eslint-disable @typescript-eslint/no-explicit-any */
import get from 'lodash/get';
import every from 'lodash/every';
import { AccommodationRoomStatus, AccommodationRoomUserStatus, RoomType } from './types';

export function getUserFullname(userId: string, usersById: Record<string, any>): string {
  const user = get(usersById, userId);
  if (!user) return '';
  return `${user.firstName || ''} ${user.lastName || ''}`;
}

export const iconForStatus = {
  [AccommodationRoomUserStatus.ACCEPTED]: {
    name: 'check',
    backgroundColor: '#bbefb9',
    color: '#59B655',
  },
  [AccommodationRoomUserStatus.PENDING]: {
    name: 'hourglass half',
    backgroundColor: '#f7df9b',
    color: '#dfa500',
  },
  [AccommodationRoomUserStatus.REJECTED]: {
    name: 'times',
    backgroundColor: '#fca3a7',
    color: '#C93B33',
  },
  [AccommodationRoomUserStatus.CANCELED]: {
    name: 'times',
    backgroundColor: '#fca3a7',
    color: '#C93B33',
  },
  [AccommodationRoomUserStatus.QUOTA_ERROR]: {
    name: 'exclamation',
    backgroundColor: '#f5cf9a',
    color: '#d37e06',
  },
};

export function areAllRoomsRejected(rooms: RoomType[]): boolean {
  return every(
    rooms,
    ({ status }) =>
      status === AccommodationRoomStatus.REJECTED || status === AccommodationRoomStatus.QUOTA_ERROR,
  );
}

import cx from 'classnames';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { injectContainerIfNeeded } from '../../cms/CmsScreen';
import Banner from '../../headers/blocks/Banner';
import BannerAds from '../../home/blocks/BannerAds';
import CloudTvBlock from '../../home/blocks/CloudTvBlock/CloudTvBlock';
import CloudTvCommentsBlock from '../../home/blocks/CloudTvBlock/components/CloudTvCommentsBlock';
import CountdownBlock from '../../home/blocks/CountdownBlock';
import FlocklerBlock from '../../home/blocks/FlocklerBlock';
import ImageWithIcons from '../../home/blocks/ImageWithIcons';
import ImageWithZones from '../../home/blocks/ImageWithZones';
import NextLiveCountdownBlock from '../../home/blocks/NextLiveCountdownBlock';
import SocialWallWidgetBlock from '../../home/blocks/SocialWallWidgetBlock';
import TiltedMenuBlock from '../../home/blocks/TiltedMenuBlock';
import WorkshopsWithRegistrationBlock from '../../home/blocks/WorkshopsWithRegistrationBlock';
import UpcomingSessions from '../../home/components/UpcomingSessions';
import UserSchedule from '../../profile/blocks/UserSchedule';
import UserProfileNetworkingBlock from '../../profile/components/UserProfileNetworkingBlock';
import SocialWallBlock from '../../social-wall/blocks/SocialWallBlock';
import Blocks from '../Blocks';
import ContactsBlock from '../ContactsBlock';
import GridLayout from '../GridLayout';
import HeaderBlock from '../HeaderBlock';
import RestrictOpeningTimesBlock from '../RestrictOpeningTimesBlock';
import ActionsBlock from './ActionsBlock';
import { Action } from './ActionsBlock/ActionsBlock';
import AddressBlock from './AddressBlock';
import BlockWrapper from './BlockWrapper.tsx';
import BrowserCheckBlock from './BrowserCheckBlock';
import CarouselBlock from './CarouselBlock';
import CategoryWorkshopsBlock from './CategoryWorkshopsBlock';
import ClickableMapBlock from './ClickableMapBlock';
import CmsModalBlock from './CmsModal';
import CMSWorkshopLiveBlock from './CMSWorkshopLiveBlock';
import ContactBlock from './ContactBlock';
import CTATileBlock from './CTATileBlock';
import CTATilesBlock from './CTATilesBlock';
import DataVoteBlock from './DataVoteBlock';
import FaqBlock from './FaqBlock';
import FilesBlock from './FilesBlock';
import HtmlBlock from './HtmlBlock';
import IconGridBlock from './IconGridBlock';
import IframeBlock from './IframeBlock';
import ImageBlock from './ImageBlock';
import ItemGridBlock from './ItemGridBlock';
import LogosBlock from './LogosBlock';
import PhotoGalleryBlock from './PhotoGalleryBlock';
import PlanBlock from './PlanBlock';
import ProgramBlock from './ProgramBlock';
import QrcodeBlock from './QrcodeBlock';
import ReplayBlock from './ReplayBlock';
import RoomingBlock from './RoomingBlock/RoomingBlock';
import SidebarMenuBlock from './SidebarMenuBlock';
import SpeakersBlock from './SpeakersBlock';
import UserPreferencesModalBlock from './UserPreferencesModalBlock';
import UserProfileModalBlock from './UserProfileModalBlock';
import VideoBlock from './VideoBlock';
import VideosBlock from './VideosBlock';
import { WorkshopAgendaBlock } from './WorkshopAgendaBlock/WorkshopAgendaBlock';
import WorkshopFeedbackPopupBlock from './WorkshopFeedbackPopupBlock';
import WorkshopFilesBlock from './WorkshopFilesBlock';
import WorkshopsBlock from './WorkshopsBlock/WorkshopsBlock';
import WorkshopsMapBlock from './WorkshopsMapBlock';
import WorkshopVideoBlock from './WorkshopVideoBlock';
import AchievementsBlock from './AchievementsBlock';

export const CmsBlocks = ({ blocks, injectContainer = false }) => (
  <Blocks
    blocks={blocks}
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    blockComponents={cmsFields}
    blockWrapper={injectContainer ? injectContainerIfNeeded : undefined}
  />
);

const RowBlock = ({ id, blocks, className, style, _id, injectContainer, stretched }) => (
  <Grid id={id}>
    <Grid.Row
      className={cx('cms-page--block', 'column--row', _id, className)}
      style={style}
      stretched={stretched}
    >
      <CmsBlocks blocks={blocks} injectContainer={injectContainer} />
    </Grid.Row>
  </Grid>
);

RowBlock.defaultProps = {
  blocks: [],
  className: '',
  id: undefined,
  injectContainer: false,
  stretched: true,
  style: {},
};

RowBlock.propTypes = {
  _id: PropTypes.string.isRequired,
  blocks: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  id: PropTypes.string,
  injectContainer: PropTypes.bool,
  style: PropTypes.object,
  stretched: PropTypes.bool,
};

const ColumnBlock = ({ id, _id, width, blocks, className, injectContainer }) => (
  <Grid.Column
    id={id}
    className={cx('cms-page--block', `column--block`, _id, className)}
    width={width}
    style={{ flex: width ? undefined : 1 }}
  >
    <CmsBlocks blocks={blocks} injectContainer={injectContainer} />
  </Grid.Column>
);

export const cmsFields = {
  action: Action,
  actions: ActionsBlock,
  address: AddressBlock,
  agenda: UserSchedule,
  workshopAgenda: WorkshopAgendaBlock,
  banner: Banner,
  bannerAds: BannerAds,
  blockContainer: BlockWrapper,
  browserCheck: BrowserCheckBlock,
  carousel: CarouselBlock,
  categoryWorkshops: CategoryWorkshopsBlock,
  clickableMap: ClickableMapBlock,
  contacts: ContactsBlock,
  cloudTv: CloudTvBlock,
  cloudTvComments: CloudTvCommentsBlock,
  cmsModal: CmsModalBlock,
  column: ColumnBlock,
  contact: ContactBlock,
  countdown: CountdownBlock,
  ctaTile: CTATileBlock,
  ctaTiles: CTATilesBlock,
  dataVotes: DataVoteBlock,
  flockler: FlocklerBlock,
  faq: FaqBlock,
  files: FilesBlock,
  gallery: PhotoGalleryBlock,
  grid: GridLayout,
  header: HeaderBlock,
  html: HtmlBlock,
  iconGrid: IconGridBlock,
  iframe: IframeBlock,
  image: ImageBlock,
  imageWithIcons: ImageWithIcons,
  imageWithZones: ImageWithZones,
  itemGrid: ItemGridBlock,
  logos: LogosBlock,
  nextLiveCountdown: NextLiveCountdownBlock,
  networking: UserProfileNetworkingBlock,
  qrcode: QrcodeBlock,
  plan: PlanBlock,
  program: ProgramBlock,
  replay: ReplayBlock,
  restrictOpeningTimes: RestrictOpeningTimesBlock,
  rooming: RoomingBlock,
  row: RowBlock,
  sidebarMenuBlock: SidebarMenuBlock,
  socialWall: SocialWallBlock,
  socialWallWidget: SocialWallWidgetBlock,
  speakers: SpeakersBlock,
  achievements: AchievementsBlock,
  tiltedMenu: TiltedMenuBlock,
  upcomingSessions: UpcomingSessions,
  userProfileModal: UserProfileModalBlock,
  userPreferencesModal: UserPreferencesModalBlock,
  video: VideoBlock,
  videos: VideosBlock,
  workshopFiless: WorkshopFilesBlock,
  workshopFeedbackPopup: WorkshopFeedbackPopupBlock,
  workshopLive: CMSWorkshopLiveBlock,
  workshopsMap: WorkshopsMapBlock,
  workshopVideo: WorkshopVideoBlock,
  workshopsWithRegistration: WorkshopsWithRegistrationBlock,
  workshops: WorkshopsBlock,
};

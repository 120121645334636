/* eslint-disable react/require-default-props */
/** @jsxImportSource @emotion/react */
import Contacts, { ContactsProps } from '../components/Contacts';
import { ContactsCSSProps, useContactsCSS } from '../hooks';
import { ContactsDesign } from '../types';
import './ContactsVariantInfocard.scss';

function actionBackgroundColor({ actions, design }: ContactsCSSProps): string[] {
  return (actions || []).map(
    ({ _id, color }) => `
.Action__Icon .icon, .Action__Value{
  color: ${color || design?.colors?.primary};
}`,
  );
}

const ContactsVariantInfocard = (
  props: ContactsProps & { design: ContactsDesign },
): JSX.Element => {
  const style = useContactsCSS(props, actionBackgroundColor);
  return <Contacts css={style} {...props} />;
};

export default ContactsVariantInfocard;

import {
  ACCEPT_INVITE,
  CHAT_LIST_LOADED,
  CHAT_SEND_MESSAGE,
  CHAT_STARTED,
  CHAT_STOPPED,
  CHAT_LOADED,
  INVITE_RECEIVED,
  CHAT_REMOVED,
  INVITE_SENT,
  MESSAGES_LOADED,
  REFUSE_INVITE,
  SEND_INVITE,
  START_CHAT,
  STOP_CHAT,
  CHAT_DELETE_MESSAGE,
  MESSAGE_DELETED,
  CHAT_SEND_VISIO_REQUEST,
  CHAT_SEND_VISIO_ENDED,
} from './networking.types';

export const startNetworkingChat = (chatId) => ({
  type: START_CHAT,
  payload: {
    chatId,
  },
});
export const stopNetworkingChat = (chatId) => ({
  type: STOP_CHAT,
  payload: {
    chatId,
  },
});
export const acceptInvite = (chatId) => ({
  type: ACCEPT_INVITE,
  payload: {
    chatId,
  },
});
export const refuseInvite = (chatId) => ({
  type: REFUSE_INVITE,
  payload: {
    chatId,
  },
});
export const sendInvite = (invited, message, status) => ({
  type: SEND_INVITE,
  payload: {
    invited,
    message,
    status,
  },
});
export const sendMessage = (chat, message) => ({
  type: CHAT_SEND_MESSAGE,
  payload: {
    chat,
    message,
  },
});
export const sendVisioRequest = (chat, visio) => ({
  type: CHAT_SEND_VISIO_REQUEST,
  payload: {
    chat,
    visio,
  },
});
export const sendVisioEnded = (chat, visio) => ({
  type: CHAT_SEND_VISIO_ENDED,
  payload: {
    chat,
    visio,
  },
});
export const deleteMessage = (chat, messageId) => ({
  type: CHAT_DELETE_MESSAGE,
  payload: {
    chat,
    messageId,
  },
});

export const networkingChatStarted = (chatId) => ({
  type: CHAT_STARTED,
  payload: {
    chatId,
  },
});
export const networkingChatStopped = (chatId) => ({
  type: CHAT_STOPPED,
  payload: {
    chatId,
  },
});
export const networkingChatMessagesLoaded = (chatId, messages) => ({
  type: MESSAGES_LOADED,
  payload: {
    chatId,
    messages,
  },
});
export const inviteReceived = (chatId, chat) => ({
  type: INVITE_RECEIVED,
  payload: {
    chatId,
    chat,
  },
});
export const chatLoaded = (chatId, chat) => ({
  type: CHAT_LOADED,
  payload: {
    chatId,
    chat,
  },
});
export const chatRemoved = (chatId) => ({
  type: CHAT_REMOVED,
  payload: {
    chatId,
  },
});
export const chatListLoaded = (chats) => ({
  type: CHAT_LIST_LOADED,
  payload: {
    chats,
  },
});

export const inviteSent = (chatId, chat) => ({
  type: INVITE_SENT,
  payload: {
    chatId,
    chat,
  },
});
export const messageDeleted = (chatId, messageId) => ({
  type: MESSAGE_DELETED,
  payload: {
    chatId,
    messageId,
  },
});

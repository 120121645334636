/* eslint-disable @typescript-eslint/no-explicit-any */
import partition from 'lodash/partition';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Icon } from 'semantic-ui-react';
import { bem } from '../../../../core/design/bem';
import { useMe } from '../../../../profile/hooks';
import ImageIcon from '../../../ImageIcon';
import { useRoomHeader } from '../hooks';
import {
  AccommodationRoomStatus,
  AccommodationRoomUserStatus,
  RoomingSession,
  RoomType,
} from '../types';
import './Room.scss';
import UserStatus from './UserStatus';

const css = bem('RoomCard');
const translationPrefix = 'blocks.rooming';

type RoomProps = {
  onStatusChange: any;
  room: RoomType;
  roomingSession: RoomingSession;
  usersById: Record<string, any>;
};

const Room = (props: RoomProps): JSX.Element => {
  const { t } = useTranslation();
  const { onStatusChange, room, usersById, roomingSession } = props;
  const { _id: roomId, users = [], owner, status: roomStatus } = room;
  const me = useMe();
  const header = useRoomHeader(room, roomingSession, usersById);
  const isOwner = me._id === owner?._id;
  const [organizer, others] = partition(users, (u) => u.userId === owner._id);
  const { _id: roomUserId, status: userStatus } = users.find((u: any) => u.userId === me._id) || {};
  return (
    <Card fluid className={css().toString()}>
      <Card.Content className={css('content').toString()}>
        <div className={css('header')}>{header}</div>
        <div className={css('users')}>
          {users.length > 1 && (
            <div className="count">
              <ImageIcon
                icon={{
                  uri:
                    'https://storage.gra.cloud.ovh.net/v1/AUTH_1aa44ccb48c74c7f956a0ce09a3527ea/static001/events/PXvSPJkCSNvuk6/files/PEBP4ac6GWaKHN.png',
                }}
                maxHeight={18}
              />
              <div className="roommates">
                {t(`${translationPrefix}.roommates`, { count: users.length })}
              </div>
            </div>
          )}
          <UserStatus user={organizer[0]} usersById={usersById} isOrganizer />
          {others.map((user: any) => {
            const { _id } = user;
            return <UserStatus key={_id} user={user} usersById={usersById} />;
          })}
        </div>
      </Card.Content>
      {!isOwner &&
        roomStatus === AccommodationRoomStatus.PENDING &&
        userStatus === AccommodationRoomUserStatus.PENDING && (
          <div className={css('actions')}>
            <Button
              className="refuse"
              icon
              onClick={() =>
                onStatusChange(roomId, roomUserId, AccommodationRoomUserStatus.REJECTED)
              }
            >
              <Icon name="times" /> {t(`${translationPrefix}.refuse-roommate`)}
            </Button>
            <Button
              className="accept"
              icon
              onClick={() =>
                onStatusChange(roomId, roomUserId, AccommodationRoomUserStatus.ACCEPTED)
              }
            >
              <Icon name="check" /> {t(`${translationPrefix}.accept-roommate`)}
            </Button>
          </div>
        )}
    </Card>
  );
};

export default Room;

import React from 'react';
import { Likes, SocialWallMessage } from '../types/SocialWallTypes';
import Album from './Album';
import Grid from './Grid';
import Masonry from './Masonry';

export { Album, Grid, Masonry };

export const variants = {
  masonry: Masonry,
  grid: Grid,
  album: Album,
};

type SocialWallVariantProps = {
  variant: 'masonry' | 'grid' | 'album';
  items: SocialWallMessage[];
  likes?: Likes;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  masonryConfig: any;
  openLightbox: (id: string) => void;
};

const SocialWallVariant = ({
  variant,
  items,
  likes,
  masonryConfig,
  openLightbox,
}: SocialWallVariantProps): JSX.Element => {
  const Component = variants[variant] ?? Masonry;
  return (
    <Component
      items={items}
      likes={likes}
      masonryConfig={masonryConfig}
      openLightbox={openLightbox}
    />
  );
};

SocialWallVariant.defaultProps = {
  likes: {} as Likes,
};

export default SocialWallVariant;

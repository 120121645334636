/* eslint-disable operator-linebreak */
import cx from 'classnames';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Label } from 'semantic-ui-react';

import { useTracking } from '../../Context';
import { userAgenda } from '../../agenda/store/agenda.selectors';
import EntityLink, { useEntityPath } from '../../components/EntityLink';
import ShareSocialNetworkButton from '../../components/ShareSocialNetwork';
import { eventTags } from '../../core/trackers/events';
import { useIsAnonymous } from '../../profile/hooks';
import { workshopProptypes } from '../../propTypes';
import store from '../../shared/Store';
import { urlJoin } from '../../utils/stringUtils';
import { useWorkshopSessionRegistration } from '../../workshop-session/store/workshopSessions.hooks';

import { useWorkshopRegistration } from '../utils/workshopRegistrationUtils';

const translationPrefix = 'workshops.workshop.actions';

export function useActionRegistration(workshop) {
  const isAnonymousUser = useIsAnonymous();
  const agenda = useSelector(userAgenda);
  const { registerWorkshop, unregisterWorkshop } = useWorkshopRegistration(workshop);
  const { registerWorkshopSession, unregisterWorkshopSession } = useWorkshopSessionRegistration(
    workshop,
  );

  const registration = agenda.find((a) => a._id === workshop?._id);

  return {
    isAnonymousUser,
    registerWorkshop,
    unregisterWorkshop,
    registerWorkshopSession,
    unregisterWorkshopSession,
    registration,
  };
}

export function getActionOptions(action, registration, t, isSelected) {
  const { key, label, icon } = action;
  switch (key) {
    case 'save': {
      if (!registration)
        return {
          label: label || t(`${translationPrefix}.${!isSelected ? 'save' : 'selected'}`),
          icon: 'star outline',
          type: 'save',
          className: 'save',
        };
      return {
        label: label || t(`${translationPrefix}.unsave`),
        icon: 'star',
        type: 'unsave',
        className: 'unsave',
      };
    }
    case 'learn-more': {
      return { label: label || 'Learn more', type: 'learn-more' };
    }
    case 'replay':
    case 'share':
    default:
      return { icon, label };
  }
}

const ActionButton = ({ action, registration, onClick, ...rest }) => {
  const { t } = useTranslation();
  const { type, icon, label, style, size, className } = action;
  const { as, mandatory, entity, isSelected, isFull } = rest;
  const isSave = type === 'save' || type === 'unsave';
  if (isSave && mandatory)
    return (
      <Label className={cx('action mandatory', className)}>
        {t(`${translationPrefix}.mandatory`)}
      </Label>
    );
  if (isSave && isFull) {
    return <Label className={cx('action full', className)}>{t(`${translationPrefix}.full`)}</Label>;
  }
  return (
    <Button
      entity={entity}
      as={as}
      size={size}
      className={cx('action', className, { selected: isSelected })}
      onClick={(e) => (onClick ? onClick(e, action) : noop)}
      labelPosition="right"
      style={style}
      icon
      animated={isSave && registration}
    >
      {isSave && registration ? (
        <>
          <Button.Content hidden>{t(`${translationPrefix}.unregister`)}</Button.Content>
          <Button.Content visible>{label}</Button.Content>
        </>
      ) : (
        label
      )}

      <Icon name={icon} style={{ backgroundColor: 'transparent' }} />
    </Button>
  );
};

ActionButton.defaultProps = {
  className: '',
  onClick: undefined,
  registration: undefined,
};

ActionButton.propTypes = {
  action: PropTypes.object.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  registration: PropTypes.object,
};

function computeEntityUrl(path) {
  if (!path) return null;
  const { origin, pathname } = window.location;
  if (path[0] === '?') {
    // Query string, use current path
    return urlJoin([origin, pathname]) + path;
  }
  return urlJoin([origin, window.__DATA__.basename, path]);
}

export const EntityShareButton = ({ action, entity, onClick }) => {
  const { trackEvent } = useTracking();

  const { title } = entity;
  const { path } = useEntityPath({ kind: 'workshops', ...entity });

  const shareUrl = computeEntityUrl(path);

  if (!shareUrl) return null;

  const { className, icon = 'share', itemProps = {}, label = 'Share', size, style } = action;
  const { iconSize, hashtag, hashtags } = itemProps;

  const handleClick = (e) => {
    onClick(e, action);
  };

  const handleShare = (type) => {
    trackEvent(eventTags.SHARE_SOCIAL_CLICK, {
      userId: store.userId,
      item: entity,
      kind: 'workshops',
      action: { type: 'share' },
      entityType: 'shareSocialNetwork',
      sharingType: type,
    });
  };

  return (
    <ShareSocialNetworkButton
      action={action}
      size={size}
      className={className}
      buttonIcon={icon}
      buttonLabel={label}
      iconSize={iconSize}
      hashtag={hashtag}
      hashtags={hashtags}
      onClick={handleClick}
      onShare={handleShare}
      shareTitle={title}
      shareUrl={shareUrl}
      style={style}
    />
  );
};

EntityShareButton.defaultProps = {
  entity: {},
};

EntityShareButton.propTypes = {
  action: PropTypes.object.isRequired,
  entity: PropTypes.object,
  onClick: PropTypes.func.isRequired,
};

const WorkshopActions = ({ workshop, actions, onClick, onSelect }) => {
  const { trackEvent } = useTracking();
  const { t } = useTranslation();
  const [isSelected, setIsSelected] = useState(false);
  const history = useHistory();
  const {
    isAnonymousUser,
    registerWorkshop,
    unregisterWorkshop,
    registerWorkshopSession,
    unregisterWorkshopSession,
    registration,
  } = useActionRegistration(workshop);

  if (!actions || actions.length === 0) return null;
  const { mandatory, quota, usersCount } = workshop;
  const isFull = quota - usersCount === 0;

  const handleAction = (e, action) => {
    const formattedAction = { ...action, ...getActionOptions(action, registration, t, isSelected) };
    e.preventDefault();
    e.stopPropagation();

    switch (action.type) {
      case 'save': {
        const { onlySelect = false } = action;
        if (isAnonymousUser) {
          onClick(true);
        } else if (!registration) {
          if (onlySelect) {
            if (onSelect) {
              onSelect(workshop);
              setIsSelected(!isSelected);
            }
          } else if (workshop.workshopId) registerWorkshopSession();
          else registerWorkshop();
        }
        return action.type;
      }
      case 'unsave': {
        if (isAnonymousUser) {
          onClick(true);
        } else if (workshop.workshopId) unregisterWorkshopSession();
        else unregisterWorkshop();
        setIsSelected(false);
        return action.type;
      }
      case 'share':
        trackEvent(eventTags.ITEM_ACTION_CLICK, { item: workshop, action: formattedAction });
        return action.type;
      case 'replay': {
        const { reference } = workshop;
        if (reference) {
          history.push(reference); // navigate to reference
        }
        trackEvent(eventTags.ITEM_ACTION_CLICK, { item: workshop, action: formattedAction });
        return action.type;
      }
      default:
        return noop;
    }
  };
  return (
    <div className="actions">
      {actions.map((action) => {
        const { key } = action;
        const isInfo = key === 'learn-more';
        if (key === 'save' && !workshop) return null;
        if (key === 'share')
          return (
            <EntityShareButton key={key} action={action} onClick={handleAction} entity={workshop} />
          );
        if (key === 'replay' && !workshop?.reference) return null;
        return (
          <ActionButton
            key={key}
            action={{ ...action, ...getActionOptions(action, registration, t, isSelected) }}
            onClick={isInfo ? undefined : handleAction}
            as={isInfo ? EntityLink : undefined}
            entity={{ ...workshop, kind: 'workshops' }}
            mandatory={mandatory}
            isSelected={isSelected}
            isFull={isFull}
            registration={registration}
          />
        );
      })}
    </div>
  );
};

WorkshopActions.defaultProps = {
  actions: [],
  onClick: undefined,
  onSelect: undefined,
};

WorkshopActions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func,
  onSelect: PropTypes.func,
  workshop: PropTypes.shape(workshopProptypes).isRequired,
};

export default WorkshopActions;

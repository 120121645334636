/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { bem } from '../../../../core/design/bem';
import CdnImage from '../../../CdnImage';
import ExpiringNavLink from '../../../ExpiringNavLink';
import ImageIcon from '../../../ImageIcon';
import { BackgroundImage, IconOrImage } from '../types';
import { Item } from '../variants/variant.types';
import './ItemComponent.scss';

type CSSShadow = 0 | 1 | 2 | 3 | 4 | 5 | string;
type BordeRadius = number | string;

export type ItemConfig = {
  showBackground: boolean;
  showIcon: boolean;
  rightIcon?: IconOrImage;
  iconConfig?: Record<string, any>;
  shadow?: CSSShadow;
  background?: BackgroundImage;
  gridGap?: number;
  borderRadius?: BordeRadius;
  underlineTitle?: boolean;
};

export type ItemProps = {
  item: Item;
  variant: string;
  itemConfig: ItemConfig;
  style?: any;
};

const css = bem('IconGridItem');

const ItemComponent = ({ variant, item, itemConfig, style }: ItemProps): JSX.Element => {
  const {
    showBackground = true,
    showIcon = true,
    iconConfig = {},
    rightIcon,
    background: defaultBackground,
  } = itemConfig;
  const { title, background, icon, url } = item;
  return (
    <ExpiringNavLink
      to={url}
      className={css({ variant }).toString()}
      data-id={item._id}
      style={style}
    >
      <div className={css('Wrapper')}>
        {showBackground && (background?.image?.uri || defaultBackground?.image?.uri) && (
          <div className={css('Image', { fit: background?.fit || defaultBackground?.fit })}>
            <CdnImage maxWidth={600} src={background?.image?.uri || defaultBackground?.image} />
          </div>
        )}
        <div className={css('Content')}>
          {!!icon && showIcon && (
            <div className={css('Icon')}>
              <ImageIcon icon={icon} {...iconConfig} />
            </div>
          )}
          {!!title && <div className={css('Title')}>{title}</div>}
          {rightIcon && (
            <div className={css('RightIcon')}>
              <ImageIcon icon={rightIcon} />
            </div>
          )}
        </div>
      </div>
    </ExpiringNavLink>
  );
};

ItemComponent.defaultProps = {
  style: undefined,
};

export default ItemComponent;

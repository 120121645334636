import cx from 'classnames';
import React, { FC } from 'react';

import { getAchievementMedia } from '../../core/achievements/achievements.utils';
import { Achievement } from '../../types/achievements.types';
import CdnImage from '../CdnImage';

type AchievementBadgeProps = {
  achievement?: Achievement;
  className?: string;
  height: number;
};

const AchievementBadge: FC<AchievementBadgeProps> = (props) => {
  const { achievement, className, height } = props;
  if (!achievement) return null;
  const uri = getAchievementMedia(achievement);
  return (
    <div className={cx('achievement-badge--container', className)}>
      <CdnImage as="img" className="achievement-badge" maxHeight={height} src={uri} />
    </div>
  );
};

AchievementBadge.defaultProps = { achievement: undefined, className: undefined, height: 25 };

export default AchievementBadge;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Header, Icon } from 'semantic-ui-react';
import { CmsBlocks } from '../../../cms/CmsScreen';
import { bem } from '../../../core/design/bem';
import SidebarMenuItem from './components/SidebarMenuItem';
import './SidebarMenuBlock.scss';
import { SidebarMenuBlockItem } from './types';

const css = bem('SidebarMenuBlock');

type SidebarMenuBlockProps = {
  blocks: SidebarMenuBlockItem[];
};

const SidebarMenuBlock = ({ blocks }: SidebarMenuBlockProps): JSX.Element | null => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState<string | undefined>();

  if (!blocks?.length) return null;

  const selectedBlock = blocks.find((b) => b._id === selected);

  return (
    <div className={css.has({ selection: !!selected })}>
      <div className={css('Menu')}>
        {blocks.map((b) => (
          <SidebarMenuItem
            key={b._id}
            item={b}
            active={b._id === selected}
            onSelect={setSelected}
          />
        ))}
      </div>
      <div className={css('Content')}>
        {selectedBlock && (
          <>
            <a href="#" className="button--back" onClick={() => setSelected(undefined)}>
              <Icon name="angle left" /> {t('btn.back')}
            </a>
            {selectedBlock.content?.header && (
              <Header as="h2">{selectedBlock.content?.header}</Header>
            )}
            <CmsBlocks blocks={selectedBlock.blocks} />
          </>
        )}
      </div>
    </div>
  );
};

export default SidebarMenuBlock;

import OpportunityListBlock from './OpportunityListBlock';
import BookAppointmentBlock from './BookAppointmentBlock';
import VideoChatRoomBlock from './BookAppointmentBlock/VideoChatRoomBlock';
import ContactsBlock from '../../sidebar/blocks/ContactsBlock';
import CTABlock from '../../sidebar/blocks/CTABlock';
import FilesBlock from '../../sidebar/blocks/FilesBlock';
import WebinarLiveBlock from './WebinarLiveBlock/WebinarLiveBlock';
import ItemCTATilesBlock from '../../components/cms/ItemCTATilesBlock';
import StandBlock from './StandBlock';

export const sidebarBlockComponents = {
  'bookAppointment': BookAppointmentBlock,
  'opportunityList': OpportunityListBlock,
  'files': FilesBlock,
  'contacts': ContactsBlock,
  'cta': CTABlock,
  'video-chat': VideoChatRoomBlock,
  'webinar-live': WebinarLiveBlock,
  'item/ctaTiles': ItemCTATilesBlock,
  'stand': StandBlock,
};

import React from 'react';

import { Card, Icon, Label } from 'semantic-ui-react';
import SocialNetworks from './SocialNetworks';

export const CardInfo = ({ icon, style, children, ...props }) => (
  <Card.Description {...props} style={{ textAlign: 'left', ...style }}>
    {icon && <Icon name={icon} />} {children}
  </Card.Description>
);

export const CardMail = ({ icon, style, children, ...props }) => {
  return (
    <Card.Description {...props} style={{ textAlign: 'left', ...style }}>
      {icon && <Icon name={icon} />} <a href={`mailto:${children}`}>{children}</a>
    </Card.Description>
  );
};

export const CardPhone = ({ icon, style, children, ...props }) => {
  return (
    <Card.Description {...props} style={{ textAlign: 'left', ...style }}>
      {icon && <Icon name={icon} />} <a href={`tel:${children}`}>{children}</a>
    </Card.Description>
  );
};

export const CardBoolTag = ({ icon, label, color, style, ...props }) => (
  <Card.Description {...props} style={{ textAlign: 'left', marginTop: 8, ...style }}>
    <Label color={color} icon={icon} content={label} />
  </Card.Description>
);

export const cardMetaComponents = {
  extra: Card.Meta,
  description: Card.Description,
  info: CardInfo,
  boolTag: CardBoolTag,
  phone: CardPhone,
  mail: CardMail,
};

export { SocialNetworks };

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable operator-linebreak */
import get from 'lodash/get';
import isString from 'lodash/isString';
import keyBy from 'lodash/keyBy';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Header, Icon } from 'semantic-ui-react';
import { bem } from '../../../../core/design/bem';
import accommodationService from '../../../../core/services/accommodation.service';
import { useMe } from '../../../../profile/hooks';
import { RoomingSession } from '../types';
import './AddRoomForm.scss';

const css = bem('RoomForm');
const translationPrefix = 'blocks.rooming';

type AddRoomFormTypes = {
  roomingSession: RoomingSession;
  // accommodationId: string;
  collection: string;
  // config?: Record<string, any>;
  refresh: any;
};

function userToOption(user: any) {
  return {
    key: user._id,
    text: `${user.firstName} ${user.lastName}`,
    value: user._id,
  };
}

const AddRoomForm = (props: AddRoomFormTypes): JSX.Element => {
  const { t } = useTranslation();
  const me = useMe();
  const owner = { _id: me._id, collection: me.collection };
  const { roomingSession, collection, refresh } = props;
  // const { levelsToExclude = [] } = config;
  // const levels = useRoomingLevels(collection, accommodationId);
  // const filteredLevels = levels.filter((l) => !levelsToExclude.includes(l._id));
  const [selectedLevel, setSelectedLevel] = useState<any | undefined>(undefined);
  const [selectedUsers, setSelectedUsers] = useState<any[] | string>([]);
  // const userIdsWithValidRoom = useValidRoomUserIds(collection, accommodationId);

  const { accommodation, candidateRoommates, accommodationLevels } = roomingSession;
  const accommodationId = accommodation._id;

  // TODO: filter

  // const usersWithouthRoom = useMemo(
  //   () => users.filter((u) => !userIdsWithValidRoom.includes(u._id)),
  //   [users, userIdsWithValidRoom],
  // );
  const { otherUsers, usersById } = useMemo(
    () => ({
      otherUsers: candidateRoommates.filter((u) => u._id !== me._id && !u.hasValidRoom),
      usersById: keyBy(candidateRoommates, '_id'),
    }),
    [me, candidateRoommates],
  );

  const { maxUsers = 0 } = selectedLevel || {};
  const isMultiple = maxUsers > 2;

  const handleSelectUsers = (value: any) => {
    if (isMultiple) {
      setSelectedUsers(value.slice(0, maxUsers - 1));
    } else setSelectedUsers(value);
  };

  const handleValidate = async () => {
    const formattedUsers = isString(selectedUsers)
      ? [selectedUsers]
      : selectedUsers.slice(0, maxUsers - 1);
    const body = {
      levelId: selectedLevel._id,
      owner: { _id: me._id, collection: me.collection },
      roomingSession: roomingSession.roomingSession,
      users: [
        { ...owner, status: 'ACCEPTED' },
        ...formattedUsers.map((userId) => ({
          _id: userId,
          collection: get(usersById, [userId, 'collection']),
        })),
      ],
      // config: get(config, 'mailConfig', {}),
    };
    await accommodationService.addRoom(collection, accommodationId, body);
    refresh();
    setSelectedUsers([]);
  };
  return (
    <div className={css()}>
      <Form>
        <div className={css('category')}>
          <Header>
            <Icon name="bed" />
            {t(`${translationPrefix}.room-category-selection`)}
          </Header>
          <div className="header">{t(`${translationPrefix}.select-your-room-category`)}</div>
          <Form.Group>
            {accommodationLevels.map((level) => {
              const { _id, name: levelName } = level;
              return (
                <Form.Radio
                  className="radio"
                  key={_id}
                  label={levelName}
                  name={_id}
                  onChange={() => {
                    setSelectedLevel(level);
                    setSelectedUsers([]);
                  }}
                  checked={selectedLevel?._id === _id}
                />
              );
            })}
          </Form.Group>
        </div>
        {!!selectedLevel && maxUsers > 1 && (
          <div className={css('roomates')}>
            <div className="header">{t(`${translationPrefix}.select-your-roommates`)}</div>
            <Form.Dropdown
              className="dropdown"
              selection
              search
              fluid
              options={otherUsers.map(userToOption)}
              multiple={maxUsers > 2}
              value={isMultiple ? selectedUsers.slice(0, maxUsers - 1) : selectedUsers}
              onChange={(_e, { value }) => handleSelectUsers(value)}
            />
          </div>
        )}
      </Form>
      <Button
        style={{ marginTop: 10 }}
        primary
        className={css('validation')}
        disabled={
          (maxUsers <= 1 && !selectedLevel) || (maxUsers > 1 && selectedUsers.length < maxUsers - 1)
        }
        onClick={handleValidate}
      >
        <Icon name={maxUsers > 1 ? 'send' : 'check'} />
        {t(`${translationPrefix}.${maxUsers > 1 ? 'send-invitation' : 'validate'}`)}
      </Button>
    </div>
  );
};

AddRoomForm.defaultProps = {};

export default AddRoomForm;

import keys from 'lodash/keys';
import values from 'lodash/values';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, useLocation } from 'react-router-dom';
import { Button, Container, Header, Icon } from 'semantic-ui-react';
import { bem } from '../../../core/design/bem';
import { useMultiWorkshopRegistration } from '../../../workshop-session/store/workshopSessions.hooks';

const css = bem('WorkshopTable');
const translationPrefix = 'workshops.workshop';

export const SaveIcon = ({ loading }) => {
  const name = loading ? 'refresh' : 'save';
  return <Icon name={name} loading={loading} />;
};

SaveIcon.defaultProps = {
  loading: false,
};

SaveIcon.propTypes = {
  loading: PropTypes.bool,
};

/**
 * combinedSelectedSessions => selectedSessions + agenda sessions
 * @param {*} props
 * @returns
 */
function WorkshopMultipleValidation(props) {
  const { t } = useTranslation();
  const {
    selectedSessions,
    combinedSelectedSessions,
    onSelect,
    requiredChoiceTimeSlots,
    config,
  } = props;
  const { loading, registerManySessions } = useMultiWorkshopRegistration(values(selectedSessions), {
    config,
  });
  const { pathname: currentLocation } = useLocation();
  const handleValidate = async () => {
    const res = await registerManySessions();
    if (onSelect && res?.success) onSelect();
  };

  const requiredTimeSlots = keys(requiredChoiceTimeSlots);
  const requiredSelectedCount = values(pick(combinedSelectedSessions, requiredTimeSlots)).length;
  const hasRequiredSlots = requiredSelectedCount >= requiredTimeSlots.length;

  return (
    <div className={css('validation')}>
      <Container>
        <div>
          <Header as="h2" className="text">
            {t(`${translationPrefix}.table.selected-sessions-count`, {
              count: keys(combinedSelectedSessions).length,
            })}
          </Header>
          {!!requiredTimeSlots.length && (
            <div className="selected-required-count">
              {t(`${translationPrefix}.table.selected-required-count`, {
                count: requiredSelectedCount,
                total: requiredTimeSlots.length,
              })}
            </div>
          )}
        </div>
        <Button
          className="validate"
          primary
          icon
          labelPosition="right"
          onClick={handleValidate}
          disabled={!hasRequiredSlots}
        >
          <SaveIcon loading={loading} />
          {t(`${translationPrefix}.table.validate-sessions`)}
        </Button>
        {keys(selectedSessions).length > 0 && (
          <Prompt
            when
            message={(location) => t('common.unsaved-changes', { pathname: location.pathname })}
          />
        )}
        <Prompt
          when={!hasRequiredSlots}
          message={(location) => {
            if (location.pathname === currentLocation) return null;
            return t('common.required-slots', {
              requiredSlots: requiredTimeSlots.length - requiredSelectedCount,
              pathname: location.pathname,
            });
          }}
        />
      </Container>
    </div>
  );
}

WorkshopMultipleValidation.defaultProps = {
  config: {},
  onSelect: undefined,
  requiredChoiceTimeSlots: {},
};

WorkshopMultipleValidation.propTypes = {
  config: PropTypes.object,
  selectedSessions: PropTypes.object.isRequired,
  combinedSelectedSessions: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  requiredChoiceTimeSlots: PropTypes.object,
};

export default WorkshopMultipleValidation;

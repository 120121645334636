import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Menu } from 'semantic-ui-react';
import { CmsBlocks } from '../../components/cms';
import {
  DateFilter,
  DateTimeFilter,
  RouterParamFilter,
  SearchFilter,
  SelectEntity,
  SimpleFilter,
} from './components';
import SelectFilter from './components/SelectFilter';
import './MenuFilter.scss';
import { toggleFilter } from './utils';

const MenuComponents = {
  'date': DateFilter,
  'datetime': DateTimeFilter,
  'router-param': RouterParamFilter,
  'simple': SimpleFilter,
  'search': SearchFilter,
  'select-entity': SelectEntity,
  'select': SelectFilter,
};

const MenuFilters = (props) => {
  const { filterList, filters, label, filterConfig, onChange, pageId } = props;
  const { header } = filterConfig || {};
  const { t } = useTranslation();

  const handleSelect = useCallback(
    (filter, value) => {
      onChange(toggleFilter(filters, filter.dataKey, value));
    },
    [filters, onChange],
  );

  return (
    <Menu vertical fluid className="menu--filter">
      {header && (
        <Menu.Item>
          <CmsBlocks blocks={header?.blocks} />{' '}
        </Menu.Item>
      )}
      <Menu.Item>
        <Header as="h3">{label || t('filters.label')}</Header>
      </Menu.Item>
      {filterList.map((filter) => {
        if (filter.type && !(filter.type in MenuComponents)) {
          // eslint-disable-next-line no-console
          console.error('Unknown filter type', filter.type);
          return null;
        }
        const Component = MenuComponents[filter.type] || SimpleFilter;
        return (
          <Component
            key={filter.dataKey}
            filter={filter}
            value={filters[filter.dataKey]}
            onSelect={handleSelect}
            pageId={pageId}
          />
        );
      })}
    </Menu>
  );
};

export default MenuFilters;

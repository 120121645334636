/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import { bem } from '../../../../core/design/bem';
import { Workshop } from '../../../../types/workshop.types';
import { AccordionActions } from './AccordionActions';
import { AccordionDetails } from './AccordionDetails';
import './AccordionCard.scss';

const css = bem('WorkshopAccordionCard');

type AccordionCardProps = {
  actionsConfig: Record<string, any>;
  showSpeakers: boolean;
  speakersConfig: Record<string, any>;
  workshop: Workshop;
  allowOverlappingSessions?: boolean;
  tagField?: string;
};

const AccordionCard: FC<AccordionCardProps> = (props: AccordionCardProps) => {
  const { workshop, showSpeakers, speakersConfig, actionsConfig, tagField, ...itemProps } = props;
  const { _id, category } = workshop;
  return (
    <div className={css({ _id, category })}>
      <div className={css('accordion')}>
        <AccordionDetails
          workshop={workshop}
          showSpeakers={showSpeakers}
          speakersConfig={speakersConfig}
          tagField={tagField}
        />
      </div>
      <div className={css('actions')}>
        <AccordionActions workshop={workshop} actionsConfig={actionsConfig} itemProps={itemProps} />
      </div>
    </div>
  );
};
AccordionCard.defaultProps = {
  allowOverlappingSessions: false,
  tagField: undefined,
};

export default AccordionCard;

import React, { FC } from 'react';
import { Grid, Header } from 'semantic-ui-react';

import {
  useAchievements,
  useQueryAchievements,
} from '../../../core/achievements/achievements.hook';
import { bem } from '../../../core/design/bem';
import { AchievementState } from '../../../types/achievements.types';
import BlockContainer from '../../BlockContainer';
import CdnImage from '../../CdnImage';
import { BlockProps, ContainerProps } from '../types';
import './AchievementsBlock.scss';
import ProgressBar from './components/ProgressBar';
import { AchievementBadge, ProgressBarType } from './types';
import { computeAchievementBadgesByCategory, computePercentage } from './utils';

const css = bem('AchievementsBlock');

type AchievementsBlockProps = BlockProps & {
  className?: string;
  container?: ContainerProps;
  containerType?: string;
  style?: Record<string, string | number>;
  progressBar: ProgressBarType;
} & typeof SuccessBlockDefaultProps;

const SuccessBlockDefaultProps = {
  className: '',
  container: {},
  containerType: 'segment-light',
  style: {},
};

type BadgeProps = {
  badge: AchievementBadge;
};

const Badge: FC<BadgeProps> = (props) => {
  const { badge } = props;
  const { image, _id, state } = badge;
  return (
    <Grid.Column
      className={css('badge', {
        id: _id,
        unlocked: state === AchievementState.COMPLETED,
      }).toString()}
    >
      <CdnImage src={image} maxHeight={45} />
    </Grid.Column>
  );
};

const BadgeRow = ({ badges }: { badges: AchievementBadge[] }): JSX.Element => {
  const columnWidth = badges.length >= 8 ? 'equal' : 9;

  return (
    <Grid columns={columnWidth} className="row-badges">
      {badges.map((badge) => {
        return <Badge badge={badge} />;
      })}
    </Grid>
  );
};

const AchievementsBlock = (props: AchievementsBlockProps): JSX.Element | null => {
  useQueryAchievements();
  const { achievements, categories } = useAchievements();
  const { _id, className, container, containerType, title, progressBar } = props;
  const achievementBadgesListByCategory = computeAchievementBadgesByCategory(
    achievements,
    categories,
  );
  const percentage = computePercentage(achievements);

  const { icon, title: progressBarTitle } = progressBar || {};

  return (
    <BlockContainer
      data={{ id: _id }}
      {...container}
      type={containerType}
      className={css({}).mix(className)}
    >
      <Grid>
        <Grid.Column width={8} className={css('badges').toString()}>
          <Header className={css('header').toString()} as="h3">
            {title}
          </Header>
          {Object.keys(achievementBadgesListByCategory).map((type) => {
            const badges = achievementBadgesListByCategory[type] as AchievementBadge[];
            return <BadgeRow badges={badges} />;
          })}
        </Grid.Column>
        <Grid.Column width={8} className={css('ProgressBar').toString()}>
          <span className={css('ProgressBar', 'label').toString()}>{progressBarTitle}</span>
          <ProgressBar percentage={percentage} icon={icon} />
        </Grid.Column>
      </Grid>
    </BlockContainer>
  );
};

AchievementsBlock.defaultProps = SuccessBlockDefaultProps;

export default AchievementsBlock;

import React from 'react';
import { bem } from '../../../../core/design/bem';
import { SidebarMenuBlockItem } from '../types';
import './SidebarMenuItem.scss';

const css = bem('SidebarMenuBlock');

type SidebarMenuItemProps = {
  active: boolean;
  item: SidebarMenuBlockItem;
  onSelect: (_id: string) => void;
};

const SidebarMenuItem = ({ item, active, onSelect }: SidebarMenuItemProps): JSX.Element => {
  return (
    <a href="#" className={css('Item').state({ active })} onClick={() => onSelect(item._id)}>
      {item.title}
    </a>
  );
};

export default SidebarMenuItem;

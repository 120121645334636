import PropTypes from "prop-types";
import React from 'react';
import values from 'lodash/values';
import orderBy from 'lodash/orderBy';
import WorkshopList, { filterRegistered } from '../../workshops/list/WorkshopList';
import '../../../home/blocks/WorkshopsBlock.scss';
import { useRegistrationsById } from '../../../workshops/utils/workshopRegistrationUtils';
import { buildMatch } from '../../../utils/filter.utils';
import NotFoundBlock from '../../NotFoundBlock';
import { getString } from '../../../utils';
import { useSyncedCollectionWorkshops } from '../../../workshops/store/workshops.hooks';
import {
  useGroupWorkshopsWithSessions,
  useSyncedCollectionWorkshopSessions,
} from '../../../workshop-session/store/workshopSessions.hooks';
import BlockContainer from '../../BlockContainer';
import { ContainerProps } from '../types';

// eslint-disable-next-line no-shadow
enum WorkshopsBlockFilterMode {
  IDS = 'ids',
  FILTER = 'filter',
  ALL = 'all',
}

type ListTemplate = {
  variant?: string;
  minHeight?: number;
};

type WorkshopsBlockProps = {
  workshopsIds?: string[];
  filters?: any;
  hasSessions?: boolean;
  showOnlyRegistered?: boolean;
  filterMode?: WorkshopsBlockFilterMode;
  itemProps?: any;
  template?: ListTemplate;
  collection?: string;
  container?: ContainerProps;
  orderBy?: string[];
  groupBy?: any;
};

const filterWorkshops = (
  workshopByIds: Record<string, any>,
  filterMode?: WorkshopsBlockFilterMode,
  workshopIds?: string[],
  filters?: any,
) => {
  switch (filterMode) {
    case WorkshopsBlockFilterMode.IDS:
      return workshopIds?.map((w) => workshopByIds[w]) || [];
    case WorkshopsBlockFilterMode.FILTER:
      return values(workshopByIds).filter(buildMatch(filters));
    default:
      return values(workshopByIds);
  }
};

const WorkshopsBlock = (props: WorkshopsBlockProps): JSX.Element => {
  const {
    workshopsIds,
    filters,
    filterMode = WorkshopsBlockFilterMode.ALL,
    hasSessions,
    showOnlyRegistered,
    template,
    itemProps,
    groupBy,
    collection = 'workshops',
    container,
    orderBy: orderByConfig = ['startDate'],
  } = props;

  let workshops = useSyncedCollectionWorkshops(collection);
  const sessions = useSyncedCollectionWorkshopSessions(collection);

  workshops = useGroupWorkshopsWithSessions(workshops, hasSessions ? sessions : undefined);
  workshops = filterWorkshops(workshops, filterMode, workshopsIds, filters);
  workshops = orderBy(workshops, orderByConfig);

  const registrations = useRegistrationsById(hasSessions);
  const filteredWorkshops = filterRegistered(workshops, registrations, showOnlyRegistered);
  return (
    <BlockContainer {...container}>
      <WorkshopList
        centered
        workshopList={filteredWorkshops}
        itemProps={itemProps}
        template={template}
        groupBy={groupBy}
      />
      {!filteredWorkshops.length && <NotFoundBlock {...getString(`workshops.not-found`)} />}
    </BlockContainer>
  );
};

WorkshopsBlock.defaultProps = {
  workshopsIds: [],
  filterMode: WorkshopsBlockFilterMode.IDS,
  filters: undefined,
  hasSessions: false,
  showOnlyRegistered: false,
  itemProps: {},
  template: { variant: 'card' },
  collection: 'workshops',
  container: {},
  orderBy: ['startDate'],
  groupBy: PropTypes.shape({
    field: PropTypes.string.isRequired,
    type: PropTypes.string,
  }),
};

export default WorkshopsBlock;

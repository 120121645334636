/* eslint-disable operator-linebreak */
import get from 'lodash/get';
import NukaCarousel from 'nuka-carousel';
import React from 'react';
import { useMedia } from 'react-media';
import { Icon } from 'semantic-ui-react';
import { bem } from '../../../core/design/bem';
import { GLOBAL_MEDIA_QUERIES } from '../../../utils/mediaQueries';
import BlockContainer from '../../BlockContainer';
import { ContainerProps } from '../types';
import './CarouselBlock.scss';
import { DocumentVariant, ImageVariant, TextVariant, VideoVariant } from './itemVariants';
import LiStyles from './LiStyles';
import { CarouselImageProps, Item } from './types';

const css = bem('CarouselBlock');

const components = {
  document: DocumentVariant,
  image: ImageVariant,
  video: VideoVariant,
  text: TextVariant,
};

type CarouselProps = {
  _id: string;
  container?: ContainerProps;
  imageProps?: CarouselImageProps;
  itemVariant?: 'image' | 'video' | 'text';
  items?: Item[];
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  carousel?: Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: any;
} & typeof CarouselDefaultProps;

const CarouselDefaultProps = {
  container: {},
  carousel: {},
  className: '',
  component: undefined,
  imageProps: undefined,
  items: [] as Item[],
  itemVariant: 'image',
};

const CarouselBlock = ({
  _id,
  items,
  itemVariant,
  imageProps,
  container,
  carousel,
  className,
  component,
}: CarouselProps): JSX.Element | null => {
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  if (items.length === 0) return null;
  const { controls } = carousel;
  const { leftIcon = 'chevron left', rightIcon = 'chevron right' } = controls || {};
  const ItemComponent = component || get(components, itemVariant, ImageVariant);
  const slidesToShow = matches.desktop
    ? carousel?.slidesToShow
    : carousel?.slidesToShowOnMobile || 1;

  return (
    <BlockContainer {...container} className={css({ id: _id, itemVariant }).mix(className)}>
      <LiStyles _id={_id} items={items} />
      <NukaCarousel
        {...carousel}
        slidesToShow={slidesToShow}
        cellAlign={carousel?.cellAlign || 'left'}
        renderCenterLeftControls={({ previousSlide, currentSlide }) => (
          <Icon
            className="left-control"
            name={leftIcon}
            onClick={previousSlide}
            disabled={currentSlide === 0}
          />
        )}
        renderCenterRightControls={({ nextSlide, currentSlide }) => (
          <Icon
            className="right-control"
            onClick={nextSlide}
            name={rightIcon}
            disabled={currentSlide === items.length - slidesToShow || items.length <= slidesToShow}
          />
        )}
      >
        {items.map((item) => {
          return <ItemComponent key={item._id} {...item} imageProps={imageProps} />;
        })}
      </NukaCarousel>
    </BlockContainer>
  );
};

CarouselBlock.defaultProps = CarouselDefaultProps;

export default CarouselBlock;

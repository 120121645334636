/* eslint-disable react/sort-comp */
import React from 'react';
import { uuid } from '../../core/trackers/utils';

export type ModalProps<Input, Output = unknown> = {
  open?: boolean;
  onValidate: (data?: Output) => unknown;
  onCancel: () => unknown;
  [key: string]: unknown;
} & Input;

export type ModalComponent<Input, Output = unknown> =
  | React.ComponentClass<ModalProps<Input, Output>>
  | React.FC<ModalProps<Input, Output>>;

export type ModalResolveFunction<T = unknown> = (data?: T | null) => void;

export type ModalManagerFunction<Input, Output> = (data: Input) => Promise<Output | null>;

type ModalComponentItem<Input, Output = unknown> = {
  Modal: ModalComponent<Input, Output>;
  id: string;
  props: ModalProps<Input, Output>;
};

interface GlobalModalManagerState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modals: ModalComponentItem<any>[];
}

class GlobalModalManager extends React.Component<unknown, GlobalModalManagerState> {
  static instance?: GlobalModalManager;

  static async open<Input, Output>(
    Modal: ModalComponent<Input, Output>,
    modalProps: Input,
  ): Promise<Output | null> {
    if (!GlobalModalManager.instance) return null;
    return new Promise((resolve) => {
      GlobalModalManager.instance?.addModal(
        Modal,
        modalProps,
        resolve as ModalResolveFunction<Output>,
      );
    });
  }

  state: GlobalModalManagerState = {
    modals: [],
  };

  componentDidMount(): void {
    GlobalModalManager.instance = this;
  }

  addModal<Input, Output>(
    Modal: ModalComponent<Input, Output>,
    props: Input,
    resolve: ModalResolveFunction<Output>,
  ): void {
    const id = uuid();
    const handleValidate = (data?: Output) => {
      // Remove this modal
      resolve(data);

      this.setState(({ modals }) => ({ modals: modals.filter((m) => m.id !== id) }));
    };

    const modalProps = {
      ...props,
      onValidate: handleValidate,
      onCancel: () => handleValidate(), // Don't take any parameters
    };

    this.setState(({ modals }) => ({ modals: [...modals, { id, Modal, props: modalProps }] }));
  }

  render(): JSX.Element {
    const { modals } = this.state;
    return (
      <>
        {modals.map(({ id, Modal, props }) => (
          <Modal key={id} {...props} open />
        ))}
      </>
    );
  }
}

export default GlobalModalManager;

import WorkshopCard3 from '../WorkshopCard3';
import ClickableWorkshopCard from '../ClickableWorkshopCard';
import WorkshopCard3dx from '../variants/WorkshopCard3dx';
import WorkshopImageOverlayCard from '../variants/WorkshopImageOverlayCard';
import WorkshopOvhCard from '../variants/WorkshopOvhCard';
import WorkshopSessionsCard from '../WorkshopSessionsCard';
import { WorkshopWithSessions } from '../../../workshop-session/components/WorkshopGroup';
import VodCard from '../variants/VodCard';
import { AccordionCard } from '../variants/accordion';

export const variants: Record<string, any> = {
  'card': WorkshopCard3,
  'clickable': ClickableWorkshopCard,
  '3ds': WorkshopCard3dx,
  'image-overlay': WorkshopImageOverlayCard,
  'ovh': WorkshopOvhCard,
  'cardWithSessions': WorkshopSessionsCard,
  'simpleSessions': WorkshopWithSessions,
  'vod': VodCard,
  'accordion': AccordionCard,
};

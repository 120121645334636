/* eslint-disable import/no-cycle */
import { parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Button, Grid, Header, Icon, Segment, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import EntityLink from '../../components/EntityLink';
import { TrackingContext } from '../../Context';
import { cancelAppointment } from '../../sponsors/blocks/BookAppointmentBlock/utils';
import { groupEventsByDays } from '../../utils/agendaUtils';
import { localeFormat } from '../../utils/date';
import { entityForEvent } from '../../utils/hooks';
import {
  AddAppointmentToCalendar,
  AddWorkshopToCalendar,
} from '../../workshops/blocks/WorkshopAddToCalendarBlock';
import { getClassName } from '../../workshops/utils';
import { useWorkshopRegistration } from '../../workshops/utils/workshopRegistrationUtils';
import UserScheduleWorkshopTemplate from '../blocks/UserScheduleWorkshopTemplate';
import AgendaCarousel from './AgendaCarousel/AgendaCarousel';
import AgendaWeek from './AgendaWeek';
import { useWorkshopSessionRegistration } from '../../workshop-session/store/workshopSessions.hooks';
import { WorkshopActions } from '../../components/workshops/workshop-templates/actions/WorkshopActions';

const translationPrefix = 'profile';

const DeleteEventButton = ({ event }) => {
  const [loading, setLoading] = useState(false);
  const { unregisterWorkshop } = useWorkshopRegistration(event);
  const { unregisterWorkshopSession } = useWorkshopSessionRegistration(event);
  const { trackEvent } = useContext(TrackingContext);

  async function handleCancelEvent() {
    switch (event.type) {
      case 'workshop': {
        unregisterWorkshop();
        break;
      }
      case 'session': {
        unregisterWorkshopSession();
        break;
      }
      case 'appointment': {
        cancelAppointment(event, event.sponsor, { setLoading, trackEvent });
        break;
      }

      default: {
        break;
      }
    }
  }

  return (
    <Button
      color="red"
      onClick={handleCancelEvent}
      size="tiny"
      icon
      loading={loading}
      disabled={loading}
    >
      <Icon name="remove" />
    </Button>
  );
};

const EventsTable = ({ actions, events }) => {
  const { t } = useTranslation();

  return (
    <Table celled className="hide-mobile-header">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell style={{ width: 200 }}>
            {t(`${translationPrefix}.timetable`)}
          </Table.HeaderCell>
          <Table.HeaderCell>{t(`${translationPrefix}.meeting`)}</Table.HeaderCell>
          <Table.HeaderCell style={{ width: actions?.length > 0 ? 370 : 250 }}>
            {t(`workshops.workshop.add-to-calendar`)}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {events.map((event) => {
          const { _id, appointment, endDate, mandatory, startDate, group, title, type } = event;

          return (
            <Table.Row key={`schedule-workshop-${_id}`} className={getClassName(event)}>
              <Table.Cell className="date">
                {t(`${translationPrefix}.event-table.item.${!endDate ? 'startDate' : 'range'}`, {
                  startDate,
                  endDate,
                })}
              </Table.Cell>
              <Table.Cell className="title">
                <EntityLink entity={entityForEvent(event)}>{title}</EntityLink>
              </Table.Cell>
              <Table.Cell
                style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}
              >
                <WorkshopActions workshop={event} actions={actions} style={{ display: 'flex' }} />

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {(type === 'workshop' || type === 'session') && (
                    <AddWorkshopToCalendar workshop={event} primary />
                  )}
                  {type === 'appointment' && (
                    <AddAppointmentToCalendar appointment={appointment} sponsor={group} primary />
                  )}
                  &nbsp;
                  {!mandatory && <DeleteEventButton event={event} />}
                </div>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

EventsTable.defaultProps = {
  actions: undefined,
};
EventsTable.propTypes = {
  actions: PropTypes.array,
  events: PropTypes.array.isRequired,
};

const AgendaList = ({ actions, events, title }) => {
  const { t } = useTranslation();
  const { days, eventsByDay } = groupEventsByDays(events);

  return (
    <Grid.Row stretched>
      <Grid.Column width={16}>
        <Segment className="segment--schedule">
          <Header as="h3">{title}</Header>
          <p className="description">{t(`${translationPrefix}.schedule.description`, '')}</p>
          {days.length === 0 && (
            <div className="empty-schedule" style={{ textAlign: 'center' }}>
              {t(`${translationPrefix}.schedule.empty-agenda`)}
            </div>
          )}
          {days.map((day) => {
            const dayFormat = !!day && localeFormat(parseISO(day), 'PPPP');
            const footer = t(`${translationPrefix}.schedule.footer-${day}`, '');
            return (
              <>
                <Header as="h4">{dayFormat}</Header>
                <EventsTable actions={actions} events={eventsByDay[day]} />
                {footer && <p className="schedule__footer">{footer}</p>}
              </>
            );
          })}
        </Segment>
      </Grid.Column>
    </Grid.Row>
  );
};

const variants = {
  list: AgendaList,
  workshops: UserScheduleWorkshopTemplate,
  agenda: AgendaWeek,
  carousel: AgendaCarousel,
};

const Schedule = (props) => {
  const { container, events, title, variant, _id, className, itemProps, ...rest } = props;
  const Component = variants[variant] || variants.list;

  return (
    <Component
      {...rest}
      _id={_id}
      className={className}
      container={container}
      events={events}
      itemProps={itemProps}
      title={title}
    />
  );
};

Schedule.defaultProps = {
  className: undefined,
  container: undefined,
  itemProps: undefined,
  title: 'Agenda',
  variant: 'list',
};

Schedule.propTypes = {
  _id: PropTypes.string.isRequired,
  events: PropTypes.array.isRequired,
  className: PropTypes.string,
  container: PropTypes.object,
  itemProps: PropTypes.object,
  title: PropTypes.string,
  variant: PropTypes.string,
};

export default Schedule;

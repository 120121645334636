import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { workshopProptypes } from '../../propTypes';
import { userAgenda } from '../../agenda/store/agenda.selectors';
import { getString } from '../../utils';
import './WorkshopRegistrationButton.scss';
import { useWorkshopRegistration } from '../utils/workshopRegistrationUtils';
import { useIsAnonymous } from '../../profile/hooks';
import UserRegistrationModal from '../../authentication/components/UserRegistrationModal/UserRegistrationModal';

const translationPrefix = 'workshops.workshop';

const WorkshopRegistrationButton = ({ workshop, registration }) => {
  const {
    isWorkshopFull,
    isWorkshopEnded,
    loading,
    registerWorkshop,
    unregisterWorkshop,
  } = useWorkshopRegistration(workshop);
  const isAnonymous = useIsAnonymous();
  const [isOpen, setIsOpen] = useState(false);
  if (!workshop) return null;
  const { mandatory } = workshop;
  if (isWorkshopEnded) {
    return <p>{getString(`${translationPrefix}.workshop-ended`)}</p>;
  }
  if (registration || mandatory) {
    return (
      <div className="WorkshopRegistationButton__AlreadyRegistered">
        {!mandatory && (
          <>
            <p className="already-registered-label">
              {getString(`${translationPrefix}.already-registered`)}
            </p>
            <Button
              className="unregister-button"
              floated="right"
              onClick={unregisterWorkshop}
              loading={loading}
              disabled={loading}
            >
              <span className="not-hovered"> {getString(`${translationPrefix}.registered`)}</span>
              <span className="hovered"> {getString(`${translationPrefix}.unregister`)}</span>
            </Button>
          </>
        )}
        {mandatory && (
          <p className="mandatory-registered-label">
            {getString(`${translationPrefix}.mandatory-registered`)}
          </p>
        )}
      </div>
    );
  }

  return (
    <div className="WorkshopRegistationButton__Unregistered">
      {workshop.startDate ? (
        <div>
          {isWorkshopFull && (
            <p className="full-workshop-label" style={{ color: '#b44b4b', fontWeight: 'bold' }}>
              {getString(`${translationPrefix}.workshop-full`)}
            </p>
          )}
          {!isWorkshopFull && (
            <>
              <p className="interested-by-workshop-label">
                {getString(`${translationPrefix}.interested-by-this-workshop`)}
              </p>
              <Button
                primary
                className="register-button"
                floated="right"
                onClick={() => (isAnonymous ? setIsOpen(true) : registerWorkshop())}
                loading={loading}
                disabled={loading}
              >
                {getString(`${translationPrefix}.register`)}
              </Button>
            </>
          )}
        </div>
      ) : (
        <p className="registrations-not-yet-open-label">
          {getString(`${translationPrefix}.registrations-not-yet-open`)}
        </p>
      )}
      {isOpen && <UserRegistrationModal onClose={() => setIsOpen(false)} />}
    </div>
  );
};

WorkshopRegistrationButton.defaultProps = {
  workshop: undefined,
  registration: undefined,
};

WorkshopRegistrationButton.propTypes = {
  workshop: PropTypes.shape(workshopProptypes),
  registration: PropTypes.object,
};

export default connect((state, props) => ({
  registration: state.registrations.registrationsById[props.workshop?._id],
  agenda: userAgenda(state),
}))(WorkshopRegistrationButton);

import keyBy from 'lodash/keyBy';
import { toast } from 'react-toastify';

// @question : je ne comprends pas pourquoi il faut une erreur esling  Dependency cycle ici
// et dans achievement.reducers...
// eslint-disable-next-line import/no-cycle
import CdnImage from '../../components/CdnImage';
import { AchievementState } from '../../types/achievements.types';
import { getString } from '../../utils';

export function computeAchievements(achievements, userAchievements) {
  const userAchievementsById = keyBy(userAchievements, 'achievementId');
  return achievements.map((a) => {
    const ua = userAchievementsById[a._id];

    return {
      ...a,
      state: ua ? ua.state : 'NOT_STARTED',
      unlockInfo: ua,
    };
  });
}

export function getAchievementMedia(achievement) {
  if (!achievement || (!achievement?.data?.unlockedMedia && !achievement?.data?.lockedMedia)) {
    return null;
  }
  const { data, state } = achievement;
  return state === AchievementState.COMPLETED ? data.unlockedMedia : data.lockedMedia;
}

export const notifyAchievement = (achievement) => {
  const { data = {} } = achievement || {};
  const { unlockedMedia } = data;
  if (!unlockedMedia) return null;
  return toast(
    <div className="achievement-notify">
      <CdnImage className="unlock-image" src={unlockedMedia} maxHeight={160} />
      <span className="text">{getString('achievements.notification.unlock-message')}</span>
    </div>,
    { containerId: 'achievement-toast-container' },
  );
};

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Grid } from 'semantic-ui-react';

import { useSidebarValues } from './rdv.hooks';

import GridContainer from '../GridContainer';
import ListDetails from '../ListDetails.js';
import VerticalMenu from '../VerticalMenu.js';

import UserColoredCalendarBlock from '../../agenda/blocks/UserColoredCalendarBlock';
import { useScreenConfig } from '../../config/screens.context';
import { useMatchingsWithExhibitors } from '../../matching/store/matching.hooks';
import FilterMenuBlock from './blocks/FilterMenuBlock';
import { withScreenClosed } from '../../core/screens/screens.hoc';
import { CmsPageLayout } from '../../cms/CmsPage';
import { bem } from '../../core/design/bem';

const sidebarComponents = {
  'vertical-menu': VerticalMenu,
  'agenda': UserColoredCalendarBlock,
  'filter-menu': FilterMenuBlock,
};

const css = bem('page');
function RdvOffersScreen(props) {
  const { pageId } = props;
  const params = useParams();
  const { offerId } = params;
  const {
    id,
    listConfig = {},
    sidebar = {},
    scoreCriteria = 'positive',
    header,
    design,
    metadata,
    pageFooter,
    pageHeader,
  } = useScreenConfig(pageId);
  const baseOffers = useMatchingsWithExhibitors('exhibitors', scoreCriteria);
  const [offers, setOffers] = useState(baseOffers);

  const newSidebar = useSidebarValues(sidebar, {
    '*': {
      pageId,
    },
    'filter-menu': {
      onChange: setOffers,
    },
  });

  return (
    <CmsPageLayout
      className={css({
        [pageId]: true,
        cms: true,
      })
        .mix([id, pageId]) // Legacy format
        .toString()}
      header={header}
      metadata={metadata}
      designOverride={design}
      pageHeader={pageHeader}
      pageFooter={pageFooter}
    >
      <Container>
        <GridContainer sidebar={newSidebar} sidebarComponents={sidebarComponents}>
          <Grid.Column>
            <ListDetails items={offers} selectedId={offerId} config={listConfig} />
          </Grid.Column>
        </GridContainer>
      </Container>
    </CmsPageLayout>
  );
}

RdvOffersScreen.defaultProps = {
  pageId: 'matching',
};

RdvOffersScreen.propTypes = {
  pageId: PropTypes.string,
};

export default withScreenClosed(RdvOffersScreen, { className: 'page--matching' });

/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/no-explicit-any */
import keyBy from 'lodash/keyBy';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Card, Header, Icon } from 'semantic-ui-react';
import { bem } from '../../../core/design/bem';
import accommodationService from '../../../core/services/accommodation.service';
import { useMe } from '../../../profile/hooks';
import BlockContainer from '../../BlockContainer';
import { ContainerProps } from '../types';
import AddRoomForm from './components/AddRoomForm';
import Room from './components/Room';
import { useRoomsForUser } from './hooks';
import './RoomingBlock.scss';
import { AccommodationRoomUserStatus, RoomUser } from './types';
import { areAllRoomsRejected } from './utils';

const translationPrefix = 'blocks.rooming';

const css = bem('RoomingBlock');

type RoomingBlockProps = {
  collection: string;
  container?: ContainerProps;
  roomingSessionId: string;
  accommodationId: string;
  userCollections?: string[];
};

const RoomingBlock = (props: RoomingBlockProps): JSX.Element | null => {
  const me = useMe();
  const { t } = useTranslation();
  const { collection, container, roomingSessionId, accommodationId } = props;

  const { data: roomingSession } = useQuery(['rooming', 'session', roomingSessionId], () =>
    accommodationService.fetchRoomingSession(roomingSessionId, me._id),
  );

  const { invitations, requests, refresh } = useRoomsForUser(collection, accommodationId, me._id);
  // hide room requests that user rejects
  const filteredRequests = requests.filter(
    (request: any) =>
      !request.users?.find(
        (u: RoomUser) => u.userId === me._id && u.status === AccommodationRoomUserStatus.REJECTED,
      ),
  );
  const usersById = useMemo(
    () => keyBy([me, ...(roomingSession?.candidateRoommates ?? [])], '_id'),
    [me, roomingSession?.candidateRoommates],
  );

  const handleStatusChange = async (roomId: string, roomUserId: string, status: string) => {
    // update RoomUserStatus
    await accommodationService.patchUserRoom(collection, accommodationId, roomId, roomUserId, {
      status,
    });
    refresh();
  };

  if (!roomingSession) {
    return null; // Loading...
  }

  if (invitations.length > 0 || filteredRequests.length > 0) {
    return (
      <BlockContainer className={css()} {...container}>
        {filteredRequests.length > 0 && (
          <div className={css('requests')}>
            <Header>
              <Icon name="users" />
              {t(`${translationPrefix}.received-demands`)}
            </Header>
            <Card.Group itemsPerRow={3}>
              {filteredRequests.map((request: any) => (
                <Room
                  key={request._id}
                  room={request}
                  roomingSession={roomingSession}
                  usersById={usersById}
                  onStatusChange={handleStatusChange}
                />
              ))}
            </Card.Group>
          </div>
        )}
        {invitations.length > 0 && (
          <div className={css('invitations')}>
            <Header>
              <Icon name="send" />
              {t(`${translationPrefix}.sent-invitations`)}
            </Header>
            <Card.Group itemsPerRow={3}>
              {invitations.map((invitation: any) => (
                <Room
                  key={invitation._id}
                  room={invitation}
                  roomingSession={roomingSession}
                  usersById={usersById}
                  onStatusChange={handleStatusChange}
                />
              ))}
            </Card.Group>
          </div>
        )}
        {areAllRoomsRejected(requests) && areAllRoomsRejected(invitations) && (
          <AddRoomForm roomingSession={roomingSession} collection={collection} refresh={refresh} />
        )}
      </BlockContainer>
    );
  }

  return (
    <BlockContainer className={css()} {...container}>
      <AddRoomForm roomingSession={roomingSession} collection={collection} refresh={refresh} />
    </BlockContainer>
  );
};

RoomingBlock.defaultProps = {
  container: {},
  userCollections: ['participants'],
};

export default RoomingBlock;

import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';

import PhotoGalleryBlock from '../../components/cms/PhotoGalleryBlock';

function ItemGalleryBlock(props) {
  const { container, item, field, galleryProps } = props;
  const images = get(item, field, []);
  if (!images.length === 0) return null;

  const maxItems = get(galleryProps, 'maxItems');
  const photos = maxItems ? images.slice(0, maxItems) : images;

  return (
    <PhotoGalleryBlock
      className="item--photoGalleryBlock"
      container={container}
      galleryProps={galleryProps}
      images={photos}
    />
  );
}

ItemGalleryBlock.defaultProps = {
  container: {},
  field: 'images',
  galleryProps: { columns: 3, margin: 4 },
};

ItemGalleryBlock.propTypes = {
  container: PropTypes.object,
  field: PropTypes.string,
  galleryProps: PropTypes.object,
  item: PropTypes.shape(PropTypes.object).isRequired,
};

export default ItemGalleryBlock;

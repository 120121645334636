import React from 'react';
import { useMedia } from 'react-media';
import { Header } from 'semantic-ui-react';
import { bem } from '../../../core/design/bem';
import { GLOBAL_MEDIA_QUERIES } from '../../../utils/mediaQueries';
import BlockContainer from '../../BlockContainer';
import { ContainerProps } from '../types';
import './AddressBlock.scss';

const css = bem('AddressBlock');

const GoogleMapsKey = 'AIzaSyAuftLmeYQil4l-Dg7ZkTxwCLsOr5Q_dy8';

type AddressBlockProps = {
  _id: string;
  address: string;
  className?: string;
  container?: ContainerProps;
  height?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  iframeProps?: Record<string, any>;
  mapType?: 'roadmap' | 'satellite';
  mapZoom?: string;
  name?: string;
  phoneNumber?: string;
  showMap?: boolean;
};

const AddressBlockDefaultProps = {
  className: '',
  container: {},
  height: 300,
  iframeProps: {},
  mapType: 'roadmap',
  mapZoom: 17,
  name: undefined,
  phoneNumber: undefined,
  showMap: true,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getMapUri(
  config: Record<string, any>,
  isMobile: boolean,
): { mapUri: string; staticUri?: string } {
  const { address, mapZoom, mapType } = config;
  const common = `zoom=${mapZoom}&maptype=${mapType}&key=${GoogleMapsKey}`;
  const staticUri = `https://maps.googleapis.com/maps/api/staticmap?center=${address}&size=600x200&markers=color:red|${address}&${common}`;
  const mapUri = `https://google.com/maps/embed/v1/place?q=${encodeURIComponent(
    address,
  )}&${common}`;
  if (!isMobile) return { mapUri };
  return { mapUri: `https://www.google.com/maps/place/${address}`, staticUri };
}

const AddressBlock = (props: AddressBlockProps): JSX.Element | null => {
  const {
    _id,
    className,
    container,
    name,
    address,
    phoneNumber,
    showMap,
    iframeProps,
    height,
    mapType,
    mapZoom,
  } = props;
  const { mobile } = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  if (!address) return null;
  const blockClassName = css({ id: _id }).mix(className);
  const { mapUri, staticUri } = getMapUri({ name, height, address, mapType, mapZoom }, mobile);
  return (
    <BlockContainer type="segment" {...container} className={blockClassName}>
      {showMap && (
        <>
          {!mobile ? (
            <iframe
              className={css('map')}
              title={name}
              src={mapUri}
              allowTransparency
              height={height}
              style={{ border: 0, height, width: '100%' }}
              {...iframeProps}
            />
          ) : (
            <a className={css('staticMap')} href={mapUri} target="_blank" rel="noreferrer">
              <img src={staticUri} alt="static-map" style={{ width: '100%' }} />
            </a>
          )}
        </>
      )}
      <div className={css('infos')}>
        {!!name && <Header className="name">{name}</Header>}
        <div className="address">{address}</div>
        {!!phoneNumber && (
          <a href={`tel:${phoneNumber}`} className="phoneNumber">
            {phoneNumber}
          </a>
        )}
      </div>
    </BlockContainer>
  );
};

AddressBlock.defaultProps = AddressBlockDefaultProps;

export default AddressBlock;

/* eslint-disable react/no-danger */
import iFrameResize from 'iframe-resizer/js/iframeResizer';
import PropTypes from 'prop-types';
import React from 'react';

import { useCurrentUser } from '../../shared/store-user.hooks';
import { replaceValues } from '../../utils/stringUtils';
import BlockContainer from '../BlockContainer';
import { BlockContainerPropType } from '../BlockContainer/BlockContainer';
import './iframe-block.scss';

// DEPRECATED: containerType, title
function IframeBlock(props) {
  const { autoResize, containerType, container, title, src, height, iframeProps } = props;

  const { scrollToTop } = iframeProps || {};
  const resizeIfNeeded = (e) => {
    if (scrollToTop) {
      window.scrollTo(0, 0);
    }
    if (autoResize && src) {
      iFrameResize({ log: false }, e.target);
    }
  };

  const user = useCurrentUser();

  if (!src) return null;

  const iframeUrl = replaceValues(src, {
    ...user,
    userId: user?._id,
    lang: user?.lang || window.__DATA__.lang,
  });
  const iframeKey = `${src}-${autoResize ? '?resize' : '?no-resize'}`;
  const iframe = (
    <iframe
      title="title"
      key={iframeKey}
      allowTransparency
      onLoad={resizeIfNeeded}
      src={iframeUrl}
      height={height}
      width="100%"
      style={{ height }}
      {...iframeProps}
    />
  );
  return (
    <BlockContainer
      {...container}
      type={container.type || containerType || 'segment-light'}
      header={container.header || title}
      className={`block--iframe ${container.className || ''}`}
      style={!title ? { padding: 0 } : undefined}
    >
      {iframe}
    </BlockContainer>
  );
}

IframeBlock.defaultProps = {
  autoResize: true,
  containerType: undefined,
  container: {},
  height: undefined,
  iframeProps: undefined,
  title: undefined,
};

IframeBlock.propTypes = {
  autoResize: PropTypes.bool,
  container: BlockContainerPropType,
  containerType: BlockContainer.propTypes.type,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iframeProps: PropTypes.object,
  src: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default IframeBlock;

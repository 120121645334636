/* eslint-disable operator-linebreak */
/* eslint-disable import/no-cycle */
import cx from 'classnames';
import noop from 'lodash/noop';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import CdnImage from '../../../components/CdnImage';
import { LayoutContext } from '../../../components/GridLayout/GridLayout';
import { useTracking } from '../../../Context';
import { eventTags } from '../../../core/trackers/events';
import { debugNowTime } from '../../../hooks/useAutoRefresh';
import { ensureDate } from '../../../utils/date';
import { replaceValues } from '../../../utils/stringUtils';
import FeedbackPopupBlock from '../../../workshops/blocks/FeedbackPopupBlock';
import WorkshopLiveBlock, { sidebarProps } from '../../../workshops/blocks/WorkshopLiveBlock';
import { useCurrentCloudTvSession, useOtherCurrentLives } from './cloudtv.hooks';
import './CloudTvBlock.scss';
import CloudTvActions from './components/CloudTvActions';
import CloudTvLiveDescription from './components/CloudTvLiveDescription';
import CloudTvRegistration from './components/CloudTvRegistration';

const translationPrefix = 'blocks.cloudtv';

const CloudTvBlock = ({
  _id,
  title,
  container,
  countdown,
  videoProps,
  workshopFilter,
  mode,
  forceLiveStream,
  options,
  liveBlockConfig,
  ...rest
}) => {
  const {
    showOtherCurrentLives,
    showCurrentLive,
    liveDescriptionConfig = {},
    actions,
    collection,
    showInfoOnlyOnLive = false,
  } = rest;
  const { enableRegistration } = options;
  const { allowFullScreen } = videoProps;
  const { t } = useTranslation();
  const { trackEvent } = useTracking();
  const [showDescription, setShowDescription] = useState(false);
  const { upcomingLive, hasCurrentLive, availableSessions } = useCurrentCloudTvSession(mode, {
    forceLiveStream,
    workshopFilter,
    enableRegistration,
    collection,
  });

  const [cloudTvLive, setCloudTvLive] = useState(upcomingLive);

  useEffect(() => setCloudTvLive(upcomingLive), [JSON.stringify(upcomingLive)]);

  const otherCurrentLives = useOtherCurrentLives(cloudTvLive, { workshopFilter });
  const showLiveInfo = showInfoOnlyOnLive ? hasCurrentLive : true;
  const { layout, setLayout } = useContext(LayoutContext);

  if (!cloudTvLive && !availableSessions?.length) {
    // Keep current feedback open
    return (
      <>
        <FeedbackPopupBlock item={{}} />
      </>
    );
  }

  const handleAction = (action) => {
    switch (action.type) {
      case 'info': {
        trackEvent(eventTags.ITEM_ACTION_CLICK, { item: cloudTvLive, action });
        setShowDescription(!showDescription);
        return action.type;
      }
      case 'fullscreen': {
        setLayout(layout === 'default' ? 'wide' : 'default');
        return action.type;
      }
      default:
        return noop;
    }
  };

  const handleChangeLive = (workshop) => {
    setCloudTvLive(workshop);
  };

  const now = debugNowTime || Date.now();
  const useCountdown =
    countdown?.backgroundImage &&
    countdown?.maxDate &&
    now < ensureDate(countdown.maxDate).getTime();
  const startTime = cloudTvLive.startDate
    ? moment(cloudTvLive.startDate, moment.ISO_8601).toDate().getTime()
    : 0;
  const isNow = now > startTime - 2 * 60 * 1000;

  return (
    <>
      <FeedbackPopupBlock item={cloudTvLive || {}} />
      <div className={cx('block--cloudtv', _id)}>
        {showCurrentLive && hasCurrentLive && (
          <div className="currentLive">
            <span className="live">{t(`${translationPrefix}.live`)}</span>{' '}
            <span className="watch-live">
              {t(isNow ? `${translationPrefix}.watch-live` : `${translationPrefix}.coming-next`)}
            </span>
            <span className="title">{cloudTvLive.title}</span>
          </div>
        )}
        {useCountdown && <CdnImage src={countdown.backgroundImage} maxWidth={1920} />}
        {!hasCurrentLive && availableSessions?.length > 0 && (
          <CloudTvRegistration sessions={availableSessions} />
        )}
        {!useCountdown && (
          <WorkshopLiveBlock
            defaultActive
            // countdown={countdown}
            allowFullScreen={allowFullScreen}
            item={cloudTvLive}
            forceLiveStream={forceLiveStream}
            containerType={container?.type}
            title={replaceValues(container?.header || title, { item: cloudTvLive })}
            {...liveBlockConfig}
            videoProps={videoProps}
            showOtherCurrentLives={showOtherCurrentLives}
            otherCurrentLives={otherCurrentLives}
            onLiveChange={handleChangeLive}
          />
        )}
        <CloudTvActions actions={actions} session={cloudTvLive} onClick={handleAction} />
        {showLiveInfo && (
          <CloudTvLiveDescription
            isOpen={showDescription}
            onClose={() => setShowDescription(false)}
            session={cloudTvLive}
            {...liveDescriptionConfig}
          />
        )}
      </div>
    </>
  );
};

CloudTvBlock.defaultProps = {
  container: undefined,
  countdown: undefined,
  forceLiveStream: undefined,
  mode: 'agenda',
  options: {},
  showComments: false,
  showCurrentLive: false,
  showOtherCurrentLives: false,
  title: undefined,
  liveBlockConfig: {},
  videoProps: {},
  workshopFilter: {},
};

CloudTvBlock.propTypes = {
  _id: PropTypes.string.isRequired,
  container: PropTypes.object,
  countdown: PropTypes.object,
  forceLiveStream: PropTypes.object,
  mode: PropTypes.string,
  showComments: PropTypes.bool,
  showCurrentLive: PropTypes.bool,
  showOtherCurrentLives: PropTypes.bool,
  title: PropTypes.string,
  options: PropTypes.shape({
    enableRegistration: PropTypes.bool,
  }),
  liveBlockConfig: PropTypes.shape({
    sidebar: sidebarProps,
  }),
  videoProps: PropTypes.shape({
    allowFullScreen: PropTypes.bool,
  }),
  workshopFilter: PropTypes.object,
};

export default connect((state) => ({
  workshops: state.workshops.workshops,
}))(CloudTvBlock);

import { Image } from '../components/cms/types';

// eslint-disable-next-line no-shadow
export enum AchievementState {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
}

// eslint-disable-next-line no-shadow
export enum AchievementStatus {
  'PUBLISHED' = 'published',
  'UNPUBLISHED' = 'unpublished',
  'IN_REVIEW' = 'in_review',
}

type AchievementData = {
  lockedMedia: Image;
  unlockedMedia: Image;
};

export type Achievement = {
  _id: string;
  eventId: string;
  categoryId: string;
  label: string;
  points?: number;
  status: AchievementStatus;
  level?: string;
  data?: AchievementData;
} & Record<string, any>;

type CategoryData = {
  description?: string;
} & Record<string, any>;

export type Category = {
  _id: string;
  id: number;
  eventId: string;
  label: string;
  data?: CategoryData;
};

export type UserAchievement = {
  _id: string;
  userId: string;
  eventId: string;
  achievementId: number;
  state: AchievementState;
  progression: Record<string, any>[];
};

export type AchievementAndCategory = {
  achievements: Achievement[];
  categories: Category[];
};

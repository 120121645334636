/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import Collapsible from 'react-collapsible';
import { Icon } from 'semantic-ui-react';
import { bem } from '../../../core/design/bem';
import BlockContainer from '../../BlockContainer';
import { BlockProps, ContainerProps } from '../types';
import './FaqBlock.scss';

const css = bem('FaqBlock');

type Question = {
  _id: string;
  title: string;
  answer: string;
};

type SingleQuestionProps = {
  question: Question;
  open: boolean;
  styles: Record<string, any>;
};

const SingleQuestion = ({ question, open, styles = {} }: SingleQuestionProps) => {
  const [isOpen, setIsOpen] = useState(open);
  const { title = '', answer = '' } = question;
  const { bgColor, rowTitleColor, arrowColor, rowContentColor, answerBgColor } = styles;
  return (
    <Collapsible
      open={isOpen}
      trigger={
        <div className={css('question')} style={{ backgroundColor: bgColor }}>
          <button type="button" className="title" style={{ color: rowTitleColor }}>
            {title}
          </button>
          <Icon style={{ color: arrowColor || rowTitleColor }} name={!isOpen ? 'plus' : 'minus'} />
        </div>
      }
      transitionTime={200}
      onOpening={() => setIsOpen(true)}
      onClosing={() => setIsOpen(false)}
    >
      <div
        className={css('answer')}
        style={{ color: rowContentColor, backgroundColor: answerBgColor }}
        dangerouslySetInnerHTML={{ __html: answer }}
      />
    </Collapsible>
  );
};

type FaqBlockProps = BlockProps & {
  className?: string;
  container?: ContainerProps;
  collapsed: boolean;
  blocks?: Question[];
  styles?: Record<string, any>;
} & typeof FaqBlockDefaultProps;

const FaqBlockDefaultProps = {
  container: {},
  className: '',
  blocks: [],
  styles: {},
};

const FaqBlock = ({
  _id,
  className,
  container,
  collapsed,
  blocks: questions,
  styles,
}: FaqBlockProps): JSX.Element | null => {
  if (questions.length === 0) return null;
  return (
    <BlockContainer
      {...container}
      className={css({
        id: _id,
      }).mix(className)}
    >
      {questions.map((question) => (
        <SingleQuestion styles={styles} key={question._id} question={question} open={collapsed} />
      ))}
    </BlockContainer>
  );
};

FaqBlock.defaultProps = FaqBlockDefaultProps;

export default FaqBlock;

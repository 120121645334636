/* eslint-disable no-shadow */
export enum AccommodationRoomStatus {
  PENDING = 'PENDING',
  VALID = 'VALID',
  QUOTA_ERROR = 'QUOTA_ERROR',
  REJECTED = 'REJECTED',
}

export enum AccommodationRoomUserStatus {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  QUOTA_ERROR = 'QUOTA_ERROR',
}

export type Owner = {
  _id: string;
  collection: string;
};

export type RoomUser = {
  _id: string;
  userId: string;
  status: string;
};

export type RoomType = {
  _id: string;
  collection: string;
  accommodationId: string;
  levelId: string;
  owner: Owner;
  status: AccommodationRoomStatus;
  users: RoomUser[];
};

export type LevelWithCount = {
  _id: string;
  name: string;
  quota?: number;
  maxUsers?: number;
  // dates?: Record<string, LevelQuotaWithCount>;
};

type User = { _id: string; firstName: string; lastName: string; hasValidRoom?: boolean };

export interface RoomingSession {
  title: string;
  roomingSession: {
    _id: string;
    configId: string;
  };
  accommodation: {
    _id: string;
    collection: string;
  };
  accommodationLevels: LevelWithCount[];
  candidateRoommates: User[];
}

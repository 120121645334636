import { WorkshopAction } from './action.type';

export const computeActions = (config?: Record<string, undefined>): WorkshopAction[] => {
  const {
    showActions = true,
    showDetails = true,
    showReplay = true,
    showEventStatus = true,
    actions = [],
  } = config || {};

  if (actions.length) {
    return actions;
  }

  const computedActions = [];

  if (showActions) {
    computedActions.push({ _id: 'registration', type: 'registration' });
  }

  if (showReplay) {
    computedActions.push({ _id: 'replay', type: 'replay' });
  }

  if (showEventStatus) {
    computedActions.push({ _id: 'event-status', type: 'event-status' });
  }

  if (showDetails) {
    computedActions.push(
      { _id: 'see-more', type: 'see-more' },
      { _id: 'see-more-live', type: 'see-more-live' },
    );
  }

  return computedActions;
};

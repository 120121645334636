import cx from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Button, Card, Checkbox, Form, Grid, Icon, Segment, Table } from 'semantic-ui-react';
import CdnImage from '../../../components/CdnImage';
import PDFModal from '../../../components/cms/FilesBlock/components/PDFModal';
import { isPDF } from '../../../components/cms/utils';
import ImageUploader from '../../../components/ImageUploader';
import SquareContainer from '../../../components/SquareContainer';
import { TrackingContext } from '../../../Context';
import { bem } from '../../../core/design/bem';
import { eventTags } from '../../../core/trackers/events';
import useTranslations from '../../../hooks/useTranslations';
import store from '../../../shared/Store';
import { replaceValues } from '../../../utils/stringUtils';
import FieldInput from '../FieldInput';
import ResetPasswordModal from '../ResetPasswordModal';
import './UserProfile.scss';
import ordersService from '../../../core/services/orders.service';

const translationPrefix = 'profile';
const css = bem('UserProfile');

const { endpoints } = window.__DATA__;

const expandField = (field) => (typeof field === 'string' ? { key: field, type: 'string' } : field);

const UserProfileDetail = ({
  user,
  fields,
  actions,
  editing,
  qrcode,
  file,
  enableAvatar,
  enableNetworking,
  showResetPasswordButton,
  onFieldChange,
  onUpdateUser,
  className,
}) => {
  const { t } = useTranslations();
  const [showPDF, setShowPDF] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const { trackEvent } = useContext(TrackingContext);
  const trackNetworkingStatus = (status) => {
    const tag = status ? eventTags.NETWORKING_ENABLE : eventTags.NETWORKING_DISABLE;
    trackEvent(tag, {
      userId: store.userId,
    });
  };

  const hasAvatarAndQrcode = enableAvatar && qrcode?.enabled;
  const fileValue = get(user, file?.field);
  const isPdf = isPDF(fileValue?.uri);
  const { order: { status, _id: orderId } = {} } = user;
  const hasOrder = !!status;
  function getBadgeUri() {
    return `${endpoints.document}/${store.eventId}/badges/users/${user.collection}/${user._id}/badge.pdf`;
  }

  const handleDownloadInvoice = async () => {
    await ordersService.downloadInvoice(orderId);
  };

  return (
    <Grid.Row className={cx(`user-profile`, className)} stretched columns="equal">
      {enableAvatar && (
        <Grid.Column
          className={css('column', { avatar: true }).toString()}
          width={hasAvatarAndQrcode ? 4 : 5}
        >
          <Segment>
            <SquareContainer>
              <ImageUploader
                field="thumbnail"
                onChange={(json) => onUpdateUser({ thumbnail: json })}
                placeholder={t(`${translationPrefix}.image-uploader.placeholder`)}
                size="big"
                style={{ height: '100%', width: '100%' }}
                value={user.thumbnail}
              />
            </SquareContainer>
          </Segment>
        </Grid.Column>
      )}
      <Grid.Column className={css('column', { info: true }).toString()}>
        <Card fluid>
          <Card.Content>
            <Card.Header as="h3">
              {t(`${translationPrefix}.information`)}
              {hasOrder && (
                <Button
                  floated="right"
                  primary
                  basic
                  size="small"
                  compact
                  onClick={handleDownloadInvoice}
                >
                  {t(`${translationPrefix}.download-invoice`)}
                </Button>
              )}
              {fileValue?.uri && (
                <Button
                  floated="right"
                  primary
                  basic
                  size="small"
                  compact
                  onClick={() => (isPdf ? setShowPDF(true) : window.open(fileValue.uri, '_blank'))}
                >
                  {file.icon && <Icon name={file.icon} />}
                  {file.label}
                </Button>
              )}
            </Card.Header>
            {showPDF && <PDFModal file={fileValue} onClose={() => setShowPDF(false)} />}
            <Form>
              <Table basic="very" className="table--profile">
                <Table.Body>
                  {fields.map((field) => {
                    const { key, label, editable, required, mapping } = expandField(field);
                    const value = get(mapping, user[key], user[key]);
                    if (!value && (!editing || !editable)) return null;
                    return (
                      <Table.Row key={key}>
                        <Table.Cell width="6">{t(`users.field.${key}`, label || key)}</Table.Cell>
                        <Table.Cell width="10">
                          <FieldInput
                            field={field}
                            label={t(`${translationPrefix}.field.${field}.${value}`, value)}
                            value={value}
                            editable={editable && editing}
                            error={required && !value}
                            onChange={onFieldChange}
                          />
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </Form>
            {(actions || showResetPasswordButton) && (
              <div>
                {actions}
                {!actions && showResetPasswordButton && (
                  <Button size="small" icon onClick={() => setShowResetPassword(true)}>
                    <Icon name="lock" />
                    &nbsp;
                    {t(`profile.updatePassword`)}
                  </Button>
                )}
              </div>
            )}

            {showResetPassword && (
              <ResetPasswordModal onClose={() => setShowResetPassword(false)} />
            )}
          </Card.Content>
          {enableNetworking && (
            <Card.Content extra>
              <Checkbox
                toggle
                label={
                  // eslint-disable-next-line jsx-a11y/label-has-associated-control
                  <label>
                    {t(`${translationPrefix}.enableNetworking`)}
                    <br />
                    <i style={{ color: 'grey', fontSize: '0.85em' }}>
                      {t(`${translationPrefix}.networkingLabel`)}
                    </i>
                  </label>
                }
                onClick={(e, { checked }) => {
                  trackNetworkingStatus(checked);
                  onUpdateUser({ networking: checked });
                }}
                checked={user.networking}
              />
            </Card.Content>
          )}
        </Card>
      </Grid.Column>
      {qrcode?.enabled && (
        <Grid.Column
          className={css('column', { qrcode: true }).toString()}
          width={hasAvatarAndQrcode ? 4 : 5}
        >
          <Segment className={css('qrcode').toString()}>
            {qrcode?.downloadBadge && (
              <Button primary as="a" target="_blank" href={getBadgeUri()}>
                <Icon name="id badge outline" />
                {t(`${translationPrefix}.download-badge`)}
              </Button>
            )}
            <CdnImage src={replaceValues(qrcode.uri, user)} />
            {qrcode.text && <p>{qrcode.text}</p>}
          </Segment>
        </Grid.Column>
      )}
    </Grid.Row>
  );
};

UserProfileDetail.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        label: PropTypes.string,
        type: PropTypes.string,
      }),
      PropTypes.string,
    ]),
  ),
  user: PropTypes.shape({
    _id: PropTypes.string,
    collection: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    networking: PropTypes.bool,
    rewards: PropTypes.object,
    thumbnail: PropTypes.object,
    order: PropTypes.object,
  }).isRequired,
  actions: PropTypes.arrayOf(PropTypes.element),
  editing: PropTypes.bool,
  onFieldChange: PropTypes.func.isRequired,
  onUpdateUser: PropTypes.func.isRequired,
  enableNetworking: PropTypes.bool,
  enableAvatar: PropTypes.bool,
  file: PropTypes.object,
  qrcode: PropTypes.shape({
    downloadBadge: PropTypes.bool,
    enabled: PropTypes.bool,
    uri: PropTypes.string.isRequired,
    text: PropTypes.string,
  }),
  showResetPasswordButton: PropTypes.bool,
  className: PropTypes.string,
};

UserProfileDetail.defaultProps = {
  fields: [],
  actions: [],
  editing: false,
  qrcode: undefined,
  file: undefined,
  enableNetworking: false,
  enableAvatar: false,
  showResetPasswordButton: false,
  className: undefined,
};

export default UserProfileDetail;

import { FC } from 'react';
import { useDispatch } from 'react-redux';
import './GamificationProvider.scss';
import { useGamification } from '../../gamification/hooks/gamification.hook';
import { notifyAchievement } from '../../core/achievements/achievements.utils';
import {
  fetchAchievements,
  fetchUserAchievements,
} from '../../core/achievements/achievements.actions';
import { useMe } from '../../profile/hooks';
import useEvent from "../../core/hooks/useEvent.hook";

type GamificationProviderProps = Record<string, undefined>;

const { gamification = {} } = window.__DATA__;
const GamificationProvider: FC<GamificationProviderProps> = () => {
  const dispatch = useDispatch();
  const me = useMe();
  const onGamificationMessage = useEvent((type: string, message: any) => {
    // TODO: Better idea than refresh everything ?
    if (gamification.enabled) {
      notifyAchievement(message?.payload?.achievement);
      dispatch(fetchAchievements());
      dispatch(fetchUserAchievements(me._id));
    }
  });

  useGamification(onGamificationMessage);
  return null;
};

export { GamificationProvider };

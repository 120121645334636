/* eslint-disable class-methods-use-this */
import { AchievementAndCategory, UserAchievement } from '../../types/achievements.types';
import platformService from './platform.service';

class AchievementsService {
  async fetchAchievements(): Promise<AchievementAndCategory> {
    return platformService.api().url(`/achievements`).get().json();
  }

  async fetchUserAchievements(userId: string): Promise<UserAchievement[]> {
    return platformService.api().url(`/achievements/users/${userId}`).get().json();
  }
}
export default new AchievementsService();

import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Header, Item } from 'semantic-ui-react';
import { bem } from '../../../../core/design/bem';
import OptLink from '../../../../OptLink';
import CdnImage from '../../../CdnImage';
import { Item as ItemProps } from '../types';
import './Classic.scss';

type ClassicProps = {
  item: ItemProps;
};

const css = bem('item');

const Classic = ({ item }: ClassicProps): JSX.Element => {
  const { image, title, description, link, category } = item;
  return (
    <Item.Group>
      <Item className={css({ classic: true }).toString()}>
        {image && (
          <div style={{ flexShrink: 0 }}>
            <CdnImage className={css('image').toString()} src={image} maxWidth={600} srcSet />
          </div>
        )}
        <Item.Content verticalAlign="middle">
          {category && <Item.Header className="category">{category}</Item.Header>}
          <Item.Header className="title" as={link ? OptLink : Header} to={link}>
            {title}
          </Item.Header>
          {!!description && (
            <Item.Description className="description">{description}</Item.Description>
          )}
        </Item.Content>
      </Item>
    </Item.Group>
  );
};

export default Classic;

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import AutoSizeTextArea from 'react-textarea-autosize';
import { Button, Form, TextArea } from 'semantic-ui-react';
import { getString } from '../../utils';

const ChatInput = ({ onSubmit, onStartVideoCall }) => {
  const [text, setText] = useState('');

  function submit() {
    const message = text.trim();
    if (!message) return;
    onSubmit(message);
    setText('');
  }

  return (
    <Form
      className="form--chat Chat--Input"
      onKeyUp={(e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
          submit();
        }
      }}
      onSubmit={(e) => {
        e.preventDefault();
        submit();
      }}
    >
      {onStartVideoCall && (
        <Button type="submit" circular primary icon="video camera" onClick={onStartVideoCall} />
      )}
      <TextArea
        rows={1}
        as={AutoSizeTextArea}
        type="text"
        name="message"
        placeholder={getString('chat.placeholder')}
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <Button type="submit" primary circular icon="send" disabled={text.trim().length === 0} />
    </Form>
  );
};

ChatInput.defaultProps = {
  onStartVideoCall: undefined,
};

ChatInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onStartVideoCall: PropTypes.func,
};

export default ChatInput;

import React from 'react';
import { useScreenConfig } from '../../config/screens.context';
import { bem } from '../../core/design/bem';
import '../SocialWall.scss';
import { defaultReactions } from '../SocialWallCard';
import SocialWallReaction from '../SocialWallReaction';
import { Likes, SocialWallMessage } from '../types/SocialWallTypes';

const css = bem('SocialWall__Metadata');

const HoverTileDefaultProps = {
  likes: {},
};

type HoverTileProps = {
  item: SocialWallMessage;
  likes?: Likes;
} & typeof HoverTileDefaultProps;

const HoverTile = (props: HoverTileProps): JSX.Element => {
  const { item: post, likes } = props;
  const { width, height } = post;
  const { reactions = defaultReactions } = useScreenConfig('socialWall');
  return (
    <div className={css()} style={{ width, height }}>
      {reactions.map((reaction: any) => (
        <SocialWallReaction key={reaction.key} item={reaction} post={post} likes={likes} />
      ))}
    </div>
  );
};

HoverTile.defaultProps = HoverTileDefaultProps;

export default HoverTile;

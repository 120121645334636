import React, { FC } from 'react';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useRegistrationsById } from '../../../../workshops/utils/workshopRegistrationUtils';
import { replaceValues } from '../../../../utils/stringUtils';
import Store from '../../../../shared/Store';

type WorkshopLiveButtonProps = {
  workshop: Record<string, any>;
} & Record<string, any>;

const translationPrefix = 'workshops.workshop';
const WorkshopLiveButton: FC<WorkshopLiveButtonProps> = (props) => {
  const { workshop, target, ...rest } = props;
  const { t } = useTranslation();
  const { _id, workshopId, mandatory } = workshop;

  const registrations = useRegistrationsById(!!workshopId);
  const userRegistration = registrations[workshopId] || registrations[_id];

  const liveStreams = userRegistration?.liveStreams || workshop.liveStreams;
  const firstLive =
    liveStreams && liveStreams.length > 0 && liveStreams?.find((live: any) => !!live.uri)?.uri;

  if (!firstLive || (!mandatory && !userRegistration)) {
    return null;
  }

  return (
    <Button
      as="a"
      href={replaceValues(firstLive, Store.user)}
      target={target || '_blank'}
      primary
      icon="play"
      content={t(`${translationPrefix}.btn.live`)}
      style={{ width: '100%', marginTop: 15 }}
      {...rest}
    />
  );
};

WorkshopLiveButton.defaultProps = {
  target: '_blank',
};

WorkshopLiveButton.propTypes = {
  workshop: PropTypes.object.isRequired,
  target: PropTypes.string,
};

export { WorkshopLiveButton };

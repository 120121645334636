/* eslint-disable react/require-default-props */
import get from 'lodash/get';
import { useMedia } from 'react-media';
import PropTypes from 'prop-types';
import BlockContainer from '../../components/BlockContainer';
import { bem } from '../../core/design/bem';
import { containerTypePropTypes } from '../../propTypes';
import { GLOBAL_MEDIA_QUERIES } from '../../utils/mediaQueries';
import { getMapUri } from '../../components/cms/AddressBlock/AddressBlock';

const css = bem('ItemAddressBlock');

type Address =
  | string
  | {
      coordinates?: { lat: number; lng: number };
      streetNumber: string;
      street: string;
      city: string;
      postalCode: string;
      fullAddress: string;
      country: string;
    };

const Address = ({ address }: { address: Address }) => {
  if (!address) return null;
  if (typeof address === 'string') return <div className="address">{address}</div>;

  const { streetNumber, street, city, postalCode, country } = address;
  return (
    <div className="address">
      <div>
        {streetNumber} {street}
      </div>
      <div>
        {postalCode} {city}
      </div>
      <div>{country}</div>
    </div>
  );
};

function getFullAddress(address: Address) {
  if (!address) return null;
  if (typeof address === 'string') return address;
  return address.fullAddress;
}

function ItemAddressBlock({
  item,
  name,
  showMap,
  container = null,
  iframeProps,
}: {
  item: any;
  name: string;
  container?: any;
  showMap?: boolean;
  iframeProps?: any;
}): JSX.Element | null {
  const { mobile } = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const address: Address = get(item, name);
  if (!address) return null;
  if (typeof address !== 'string' && !address?.city) {
    return null;
  }
  const height = 300;
  const mapType = 'roadmap';
  const mapZoom = 17;
  const { mapUri, staticUri } = getMapUri(
    { name, height, address: getFullAddress(address), mapType, mapZoom },
    mobile,
  );

  // TODO: allow map ?
  return (
    <BlockContainer type="segment" {...container}>
      {showMap && (
        <>
          {!mobile ? (
            <iframe
              className={css('map')}
              title={name}
              src={mapUri}
              allowTransparency
              height={height}
              style={{ border: 0, height, width: '100%' }}
              {...iframeProps}
            />
          ) : (
            <a className={css('staticMap')} href={mapUri} target="_blank" rel="noreferrer">
              <img src={staticUri} alt="static-map" style={{ width: '100%' }} />
            </a>
          )}
        </>
      )}
      <div className={css('infos')}>
        {/* {!!name && <Header className="name">{name}</Header>} */}
        <Address address={address} />
        {/* {!!phoneNumber && (
          <a href={`tel:${phoneNumber}`} className="phoneNumber">
            {phoneNumber}
          </a>
        )} */}
      </div>
    </BlockContainer>
  );
}

ItemAddressBlock.defaultProps = {
  name: 'address',
};

ItemAddressBlock.propTypes = {
  item: PropTypes.object.isRequired,
  name: PropTypes.string,
  type: containerTypePropTypes,
};
export default ItemAddressBlock;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { isAfter } from 'date-fns';
import PropTypes from 'prop-types';
import { Button, Segment } from 'semantic-ui-react';
import keys from 'lodash/keys';
import noop from 'lodash/noop';
import { useTranslation } from 'react-i18next';
import {
  appointmentArrayProptypes,
  appointmentProptypes,
  workshopArrayProptypes,
} from '../../propTypes';
import './DayCalendar.scss';
import { bem } from '../../core/design/bem';
import { alpha } from '../../Styles';
import { getEventInfo, groupEventsByDateAndHour } from './utils';
import ExpiringNavLink from '../ExpiringNavLink';
import DayCalendarPopup from './DayCalendarPopup';
import { useAutoRefresh } from '../../hooks/useAutoRefresh';
import { computeMidTime } from '../../workshops/utils/session.utils';

const translationPrefix = 'components.day-calendar';

const cxCalendar = bem('day-calendar');
const cxCalendarDays = bem(cxCalendar('days').toString());
const cxCalendarHours = bem(cxCalendarDays('hours').toString());
const cxCalendarEvent = bem(cxCalendarHours('event').toString());

const Event = ({ event, config, onClick }) => {
  const { startDate, endDate } = event;
  const { t } = useTranslation();
  const { types, isSidebarDisplay = false } = config;

  const eventInfo = getEventInfo(types, event);
  const { color, title, label } = eventInfo;
  return (
    <div
      className={cxCalendarEvent()}
      style={{ backgroundColor: alpha(color, 0.4) }}
      onClick={() => onClick(eventInfo)}
    >
      <p className={cxCalendarEvent('time')}>
        {t(`${translationPrefix}.time`, { startDate, endDate })}
      </p>
      <p className={cxCalendarEvent('title').state({ mobile: isSidebarDisplay })}>{title}</p>
      {label && (
        <p
          className={cxCalendarEvent('type').state({ mobile: isSidebarDisplay })}
          style={{ backgroundColor: color }}
        >
          {label}
        </p>
      )}
    </div>
  );
};

Event.defaultProps = {
  onClick: noop,
};

Event.propTypes = {
  config: PropTypes.object.isRequired,
  event: PropTypes.oneOfType([
    PropTypes.shape(appointmentProptypes),
    PropTypes.shape(workshopArrayProptypes),
  ]).isRequired,
  onClick: PropTypes.func,
};

export function upcomingEvents(events, now) {
  if (!events) return events;
  const nowDate = new Date(now);
  return events.filter((s) => isAfter(computeMidTime(s), nowDate));
}

const TimeDivider = ({ hour }) => (
  <div className="hour">
    <div className="divider divider--left" />
    <h4>{hour}</h4>
    <div className="divider divider--right" />
  </div>
);

function DayCalendar(props) {
  const { events, config } = props;
  const { t } = useTranslation();
  const [selectedEvent, setSelectedEvent] = useState(undefined);
  const { dayFormat = 'LL', maxItems, isSidebarDisplay = false, agendaPath = '/agenda' } = config;
  const now = useAutoRefresh(60000);
  const calendarEvents = isSidebarDisplay ? upcomingEvents(events, now) : events;
  const formattedEvents = groupEventsByDateAndHour(calendarEvents.slice(0, maxItems), dayFormat);
  return (
    <Segment className={cxCalendar()}>
      <h2>{t(`${translationPrefix}.title`)}</h2>
      <p>{t(`${translationPrefix}.header`)}</p>
      {formattedEvents.length === 0 && <p>{t(`${translationPrefix}.empty-agenda`)}</p>}
      {formattedEvents.map(({ day, dayEvents }, index) => {
        return (
          <div key={day} className={cxCalendarDays()}>
            {index !== 0 && <div className="divider" />}
            <h3>{day}</h3>
            {keys(dayEvents).map((hour) => {
              const finalEvents = dayEvents[hour];
              return (
                <div key={hour} className={cxCalendarHours()}>
                  <TimeDivider hour={hour} />
                  {finalEvents.map((event) => (
                    <Event
                      key={event._id}
                      event={event}
                      config={config}
                      onClick={(e) => setSelectedEvent(e)}
                    />
                  ))}
                </div>
              );
            })}
          </div>
        );
      })}
      {isSidebarDisplay && (
        <Button className="agenda" primary as={ExpiringNavLink} to={agendaPath}>
          {t(`${translationPrefix}.view-agenda`)}
        </Button>
      )}
      {selectedEvent && (
        <DayCalendarPopup
          event={selectedEvent}
          config={config}
          onClose={() => setSelectedEvent(undefined)}
        />
      )}
    </Segment>
  );
}

DayCalendar.defaultProps = {
  config: {},
  events: [],
};

DayCalendar.propTypes = {
  config: PropTypes.object,
  events: PropTypes.oneOfType([appointmentArrayProptypes, workshopArrayProptypes]),
};

export default DayCalendar;

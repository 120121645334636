/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC } from 'react';
import { Modal } from 'semantic-ui-react';

import { useDesignConfig } from '../../config/design.context';
import { useSelectedAchievements } from '../../core/achievements/achievements.hook';
import { bem } from '../../core/design/bem';
import { useFatMe } from '../../profile/hooks';
import IframeBlock from '../cms/IframeBlock';
import BlockedByAchievementPopup from './components/BlockedByAchievementPopup';

const css = bem('BlockingPopup');

/* Pour l'instant cette BlockingPopup ne fonction qu'avec les achievements
 *  elle n'a donc pas de méthode handle close
 *  */

const BlockingPopup: FC = () => {
  const { components } = useDesignConfig();
  const { blockingPopup } = components || {};
  const { achievements, uri, ...iframeProps } = blockingPopup || {};
  const { ids: achievementIds } = achievements || {};

  if (!blockingPopup || !uri) return null;

  return (
    <BlockedByAchievementPopup
      uri={uri}
      achievementIds={achievementIds}
      iframeProps={iframeProps}
    />
  );
};

BlockingPopup.defaultProps = {};

export default BlockingPopup;

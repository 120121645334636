import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import keyBy from 'lodash/keyBy';
import { Icon } from 'semantic-ui-react';
import { useFirebase, useFirestoreDocument } from '../utils/useFirebase';
import store from '../shared/Store';

function SocialWallReaction(props) {
  const { item, likes, post } = props;
  const { id: postId, reactions = {} } = post;
  const { key, color, icon } = item;
  const userReactions = likes[postId] || [];
  const userReactionsByType = keyBy(userReactions, 'type');
  const hasUserReaction = userReactionsByType[key];

  const firebase = useFirebase();
  const userLikesRef = useMemo(
    () =>
      firebase
        ?.firestore()
        .collection('social-wall')
        .doc(store.eventId)
        .collection('users')
        .doc(store.userId)
        .collection('likes')
        .doc(`${postId}-${key}`),
    [firebase, postId, key],
  );

  const { set: setReaction, remove: deleteReaction } = useFirestoreDocument(userLikesRef);

  const handleLike = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!hasUserReaction) {
      // add a new reaction
      const reaction = {
        messageId: postId,
        type: key,
        createdAt: new Date().toISOString(),
        userId: store.userId,
      };
      await setReaction(reaction, { merge: true });
    } else {
      // delete the reaction
      await deleteReaction();
    }
  };

  return (
    <div className="SocialWall--Card__Actions--reactions__reaction">
      <Icon
        size="large"
        name={hasUserReaction ? icon : `${icon} outline`}
        color={hasUserReaction ? color : 'grey'}
        onClick={handleLike}
      />
      <p className="count">{reactions[key] || 0}</p>
    </div>
  );
}

SocialWallReaction.propTypes = {
  item: PropTypes.shape({ key: PropTypes.string, color: PropTypes.string, icon: PropTypes.string })
    .isRequired,
  likes: PropTypes.object.isRequired,
  post: PropTypes.object.isRequired,
};

export default SocialWallReaction;

/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
import get from 'lodash/get';
import React from 'react';
import { bem } from '../../../core/design/bem';
import { InfoProps } from '../types';
import './ItemInfos.scss';

const css = bem('ItemInfo');
const fieldCss = bem('field');

export type ItemInfosProps = {
  item: any;
  infos: InfoProps[];
};

const ItemInfo = ({
  item,
  name,
  as: Tag = 'div',
  blocks,
}: InfoProps & { item: any }): JSX.Element => {
  return (
    <Tag className={css({ [name]: true })}>
      {blocks.map(({ field, type, as: InnerTag = 'span', className, style }, index) => {
        const value = get(item, field);
        if (!value) return null;
        return (
          <React.Fragment key={index}>
            <InnerTag
              className={fieldCss({
                [field]: true,
                type,
              })
                .mix(className)
                .toString()}
              data-value={value}
              style={style}
            >
              {value}
            </InnerTag>{' '}
          </React.Fragment>
        );
      })}
    </Tag>
  );
};

const ItemInfos = ({ item, infos }: ItemInfosProps): JSX.Element => {
  return (
    <div className="ItemInfos">
      {infos.map((info, index) => (
        <ItemInfo key={info.name || index} {...info} item={item} />
      ))}
    </div>
  );
};

export default ItemInfos;

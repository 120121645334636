import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'semantic-ui-react';
import { bem } from '../../../../core/design/bem';
import SquareImage from '../../../SquareImage';
import { speakerThumbnailOptions } from './ClassicSpeakerCard';
import { EntityTags } from '../../../../workshops/components/WorkshopTable/WorkshopModal';
import './SquareSpeakerCard.scss';
import { speakerPropTypes } from '../../../../propTypes';
import EntityLink, { usePageForEntity } from '../../../EntityLink';
import { getString } from '../../../../utils';

const css = bem('SquareSpeakerCard');
const translationPrefix = 'speakers.card';

const SquareSpeakerCard = (props) => {
  const { defaultThumbnail, fluid, imageSize, openMode, speaker, tagFields, width } = props;
  const { company, firstName, lastName, role, logo, thumbnail = defaultThumbnail } = speaker;

  const page = usePageForEntity(speaker);

  return (
    <>
      <Card
        as={page ? EntityLink : undefined}
        className={css()}
        entity={page ? speaker : undefined}
        fluid={fluid}
        style={{ width, cursor: page ? 'pointer' : undefined }}
      >
        <div>
          <SquareImage
            as="img"
            alt={`${firstName} ${lastName}`}
            src={thumbnail}
            imageSize={imageSize}
            cdnOptions={speakerThumbnailOptions}
            className={css('avatar').toString()}
          />
        </div>
        <Card.Content className={css('content').toString()}>
          {logo && (
            <SquareImage
              as="img"
              src={logo}
              alt={`${firstName} ${lastName}`}
              className={css('logo').toString()}
              imageSize={50}
              func="fit"
            />
          )}
          <div className="info">
            <Card.Header className={css('firstNameLastName').toString()}>
              {firstName} {lastName}
            </Card.Header>
            {(company || role) && (
              <Card.Description className={css('companyRole').toString()}>
                {[company, role].filter((v) => v).join(' - ')}
              </Card.Description>
            )}
            <EntityTags tagFields={tagFields} entity={speaker} type="speaker" />
          </div>
          {openMode === 'link' && (
            <Card.Content className="navlink">
              <EntityLink autoHide entity={speaker}>
                {getString(`${translationPrefix}.view-sessions`)}
              </EntityLink>
            </Card.Content>
          )}
        </Card.Content>
      </Card>
    </>
  );
};

SquareSpeakerCard.defaultProps = {
  defaultThumbnail: undefined,
  fluid: undefined,
  imageSize: 300,
  openMode: 'none',
  tagFields: [],
  width: undefined,
};
SquareSpeakerCard.propTypes = {
  defaultThumbnail: PropTypes.string,
  fluid: PropTypes.bool,
  imageSize: PropTypes.number,
  openMode: PropTypes.string,
  speaker: speakerPropTypes.isRequired,
  tagFields: PropTypes.arrayOf(PropTypes.string),
  width: PropTypes.number,
};

export default SquareSpeakerCard;

/* eslint-disable react/require-default-props */
/** @jsxImportSource @emotion/react */
import Contacts, { ContactsProps } from '../components/Contacts';
import { ContactsCSSProps, useContactsCSS } from '../hooks';
import { ContactsDesign } from '../types';
import './ContactsVariantVisitcard.scss';

function visitCardCSS({ design }: ContactsCSSProps): string {
  const { colors } = design;
  return `
color: ${colors?.primary};

.Contact__Wrapper, .Contact__Thumbnail::after {
  background-color: ${colors?.profileBackground || '#EEE'};
}
.Action__Icon {
  background-color: ${colors?.primary};
}`;
}

const ContactsVariantVisitcard = (
  props: ContactsProps & { design: ContactsDesign },
): JSX.Element => {
  const style = useContactsCSS(props, visitCardCSS);
  return <Contacts css={style} {...props} />;
};

export default ContactsVariantVisitcard;

import React from 'react';
import { Popup } from 'semantic-ui-react';
import { userArrayProptypes } from '../../../propTypes';
import { avatarWithFallbackWhenMissing } from '../../../utils/avatarUtils';
import { replaceValues } from '../../../utils/stringUtils';
import SquareImage from '../../SquareImage';

export function getAvatarTitle(user, template) {
  if (template) return replaceValues(template, user);
  return user.name || `${user.firstName || ''} ${user.lastName || ''}`;
}

const SimpleVariant = (props) => {
  const { users, ...rest } = props;
  const { cdnOptions } = rest;
  const { size, template, imageSize, defaultThumbnail, imageKey } = rest;
  return (
    <div>
      {users.map((user) => (
        <Popup
          key={user._id}
          content={getAvatarTitle(user, template)}
          trigger={
            <SquareImage
              size={size}
              src={avatarWithFallbackWhenMissing(user[imageKey], size, defaultThumbnail)}
              imageSize={imageSize}
              circular
              style={{ cursor: 'pointer' }}
              cdnOptions={cdnOptions}
            />
          }
        />
      ))}
    </div>
  );
};

SimpleVariant.propTypes = { users: userArrayProptypes };
SimpleVariant.defaultProps = { users: [] };

export default SimpleVariant;

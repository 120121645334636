import React from 'react';
import { bem } from '../../core/design/bem';
import { useUserCollection } from '../../core/users/users.hooks';
import BlockContainer from '../BlockContainer';
import { ContainerProps } from '../cms/types';
import Contacts, { ContactsProps } from './components/Contacts';
import { defaultActions, defaultInfos } from './data';
import { ContactsDesign } from './types';
import { variants } from './variants';

const css = bem('ContactsBlock');

export type ContactsBlockProps = {
  _id: string;
  className?: string;
  container?: ContainerProps;
} & ContactsProps & { design: ContactsDesign };

const ContactsBlock = ({
  _id,
  className,
  container,
  variant,
  actions,
  infos,
  ...rest
}: ContactsBlockProps): JSX.Element | null => {
  const items = useUserCollection('contacts');
  if (!items?.length) return null;
  const ContactsVariant = variants[variant] || Contacts;
  return (
    <BlockContainer {...container} className={css({ id: _id, variant }).mix(className)}>
      <ContactsVariant variant={variant} actions={actions} infos={infos} {...rest} items={items} />
    </BlockContainer>
  );
};

ContactsBlock.defaultProps = {
  variant: 'default',
  actions: defaultActions,
  infos: defaultInfos,
};

export default ContactsBlock;

import React from 'react';
import { bem } from '../../core/design/bem';
import '../SocialWall.scss';
import { SocialWallVariant } from '../types/SocialWallTypes';
import './Grid.scss';
import ImageTile from '../components/ImageTile';

const css = bem('Grid');

const Grid = ({ items, likes, openLightbox }: SocialWallVariant): JSX.Element | null => {
  const photos = items.filter((i) => !!i.image);

  return (
    <div className={css()}>
      {photos.map((item) => (
        <ImageTile key={item.id} item={item} openLightbox={openLightbox} likes={likes} />
      ))}
    </div>
  );
};

export default Grid;

import cx from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { useMedia } from 'react-media';
import { Redirect } from 'react-router-dom';
import { Menu, Search } from 'semantic-ui-react';
import CdnImage from '../../../../components/CdnImage';
import ImageIcon from '../../../../components/ImageIcon';
import MenuMobile from '../../../../components/menu/MenuMobile';
import NavMenuItem from '../../../../components/menu/NavMenuItem';
import { useConfig } from '../../../../config/config.context';
import { useDesignConfig } from '../../../../config/design.context';
import { useLoginPath } from '../../../../config/screens.context';
import { NetworkMessagesLabel } from '../../../../networking/components/NetworkNotificationsLabel';
import store from '../../../../shared/Store';
import { SocialWallLabel } from '../../../../social-wall/SocialWallLabel';
import { GLOBAL_MEDIA_QUERIES } from '../../../../utils/mediaQueries';
import './VerticalMenu.scss';

const menuModesClassNames = {
  classic: '',
  buttons: 'secondary',
  tabs: 'secondary pointing',
};

// eslint-disable-next-line react/prop-types
const MenuItem = ({ _id, label, name, icon, path, type }) => {
  const { menu: menuConfig } = useDesignConfig();
  const { type: iconType, style, maxHeight } = menuConfig?.icon || {};
  return (
    <NavMenuItem className={cx(name, type)} to={path} exact={path === '/'}>
      <ImageIcon
        icon={icon || 'block layout'}
        type={iconType}
        style={{ marginRight: 8, ...style }}
        maxHeight={maxHeight}
      />
      {label}
      {type === 'search-field' && <Search />}
      {type === 'networking' || (name === 'networking' && <NetworkMessagesLabel color="" />)}
      {type === 'social-wall' && <SocialWallLabel color="blue" />}
    </NavMenuItem>
  );
};

const AppMenu = React.memo((props) => {
  const { menu, variant, loginPath } = props;
  const { logo } = useDesignConfig();

  // const handleDisconnect = async () => {
  //   await store.disconnect();
  // };

  if (store.mustRedirectToLogin()) {
    // Handle disconnect
    return <Redirect to={loginPath} />;
  }

  return (
    <Menu
      className={cx(menuModesClassNames[variant], 'menu--main menu--hover')}
      vertical
      icon="labeled"
    >
      {logo && (
        <Menu.Item className="item--logo">
          <CdnImage as="img" src={logo} />
        </Menu.Item>
      )}
      {menu.map((m) => (
        <MenuItem key={m._id || m.name} {...m} />
      ))}

      {/* <Menu.Menu position="right">
          {right.map((m) => this.renderItem(m))}
          <LogoutButton menuConfig={menuConfig} onClick={this.handleDisconnect} />
        </Menu.Menu> */}
    </Menu>
  );
});

AppMenu.defaultProps = {
  menu: [],
  menuConfig: {},
  variant: 'classic',
};

AppMenu.propTypes = {
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      path: PropTypes.string,
      color: PropTypes.string,
      translations: PropTypes.object,
    }),
  ),
  menuConfig: PropTypes.shape({ thumbnailButton: PropTypes.bool, icon: PropTypes.object }),
  variant: PropTypes.oneOf(['classic', 'tabs', 'buttons']),
};

const VerticalMenu = ({ menu }) => {
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const { lang, languages, menus } = useConfig();
  const loginPath = useLoginPath();

  const design = useDesignConfig();
  const { menu: menuConfig = {} } = design;
  if (matches.mobile || menuConfig.mobileOnly) {
    return (
      <MenuMobile
        menu={[
          ...menu?.map((m) => ({ ...m, menuType: 'main' })),
          ...get(menus, ['secondary', 'entries'], []).map((m) => ({ ...m, menuType: 'secondary' })),
        ].filter((m) => m.type !== 'search')}
        language={lang}
        languageOptions={languages}
        design={design}
        loginPath={loginPath}
      />
    );
  }

  return (
    <div className={`menu-wrapper menu-wrapper--${get(menuConfig, 'wrapper.variant', 'logoTop')}`}>
      <AppMenu
        menu={menu}
        menuConfig={menuConfig}
        variant={menuConfig?.entries?.variant}
        loginPath={loginPath}
      />
    </div>
  );
};

VerticalMenu.defaultProps = {
  menu: [],
};

VerticalMenu.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.object),
};

export default VerticalMenu;
